import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import OurQualitiesGridItem from "../../../components/molecules/ourQualitiesGrid/ourQualitiesGridItem";
import { OurQualitiesGrid } from "../../../components/molecules/ourQualitiesGrid/ourQualitieGrid.styled";
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons'
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from "../../../functions/windowsDiemsions"
import ContentAndImage from "../../../components/templates/contentAndImage/contentAndImage";
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

function AboutUsDE(){
    const { height, width } = useWindowDimensions();
    const isMobile = width <= 767.98;
    return(
        <Page isPadding={true}>
            <PageContent>
                <PageHeader>
                    Über uns
                </PageHeader>
                <br />
                <ContentAndImage src="images/home/about-us.jpg">
                    <OurQualitiesGrid>
                        <OurQualitiesGridItem icon={faHelmetSafety}>
                            PROFESSIONELLES UND ERFAHRENES TEAM 
                        </OurQualitiesGridItem>
                        <OurQualitiesGridItem icon={faShieldHalved}>
                            ZUVERLÄSSIGKEIT
                        </OurQualitiesGridItem>
                        <OurQualitiesGridItem icon={faClockRotateLeft}>
                            RECHTZEITIGKEIT
                        </OurQualitiesGridItem>
                        <OurQualitiesGridItem icon={faTag}>
                            GÜNSTIGE PREISE
                        </OurQualitiesGridItem>
                    </OurQualitiesGrid>
                    <Paragraph data-aos-once data-aos-duration={1000} data-aos={isMobile ? "fade-left" :"fade-up-left"}>
                        Dank der Zufriedenheit unserer Kunden mit gut und schnell ausgeführten Aufträgen sind unsere 
                        Dienstleistungen auf dem nationalen und internationalen Markt bekannt und geschätzt. 
                        Unsere spezialisierten Arbeitsteams verfügen über langjährige Erfahrung bei der Durchführung 
                        von Bohrungen unter anderem unter Straßen, Gleisen oder Flüssen.
                    </Paragraph>
                </ContentAndImage>
            </PageContent>
        </Page>
    );
}

export default AboutUsDE;