import MainHeader from "../../components/templates/mainHeader/mainHeader";
import GalleryComp from "../../components/organisms/galleryComp/galleryComp";

function GalleryEN() {
    return (
      <div>
        <MainHeader data-aos-once  data-aos="fade-right" data-aos-duration={1000} bgcolor={"whitesmoke"}>Gallery</MainHeader>
        <GalleryComp/>
      </div>
    );
}
  
export default GalleryEN;
  