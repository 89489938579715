import styled from "styled-components";
import useWindowDimensions from "../../../functions/windowsDiemsions";

const Container = styled.div`
    @media (max-width: 767.98px) {
        max-width: 540px;
    }
    
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        width: 720px;
    }
        
    @media (min-width: 991.99px) and (max-width: 1199.98px) {
        width: 960px;
    }
        
    @media (min-width: 1199.99px) {
        width: 1140px;
    }
    ${(props) => props.border && "border-bottom: 1px solid var(--lightgrey);"}
`

const Wrapper = styled.div`
    @media (max-width: 599.98px) {
        width: calc(${(props) => props.width}px - ${(props) => props.padding}px);
    }

    @media (min-width: 599.98px) and (max-width: 767.98px) {
        width: 540px;
    }
    
`

const Text = styled.div`
    top: 0;
    left: 0;
    min-width: 100%;
    text-transform: uppercase;
`

function PageHeader({children,border}){
    const { height, width } = useWindowDimensions();
    const padding = width * 0.1;

    return(
        <Container border={border}>
            <Wrapper width={width} padding={padding}>
                <Text data-aos-once  data-aos="fade-right" data-aos-duration={1000} padding={padding}>
                    <h1>{children}</h1>
                </Text>
            </Wrapper>
        </Container>
    );
}

export default PageHeader;