import "../../../styles/organisms/form/form.css"
import React, { useState } from 'react';
import InputLabel from "../../atoms/inputLabel/InputLabel";

function FormInput({input, onChange, isTextarea, rows}){

    const [focused, setFocused] = useState(false);

    const handleBlur = (e) =>{
        setFocused(true);
    }

    return(
        <InputLabel label={input.placeholder} required={input.required}>
            {isTextarea ? 
                <textarea rows={rows} required={input.required} onBlur={handleBlur} focused={focused.toString()} onChange={onChange} type={input.type} placeholder={input.placeholder} name={input.name}/>
            :
                <input pattern={input.pattern} min={input.min} required={input.required} onBlur={handleBlur} focused={focused.toString()} onChange={onChange} type={input.type} placeholder={input.placeholder} name={input.name}/>
            }
            <div className="error">{input.errorMessage}</div>
        </InputLabel>
    );
}

export default FormInput;