import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import OurQualitiesGridItem from "../../../components/molecules/ourQualitiesGrid/ourQualitiesGridItem";
import { OurQualitiesGrid } from "../../../components/molecules/ourQualitiesGrid/ourQualitieGrid.styled";
import { faHelmetSafety } from '@fortawesome/free-solid-svg-icons'
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from "../../../functions/windowsDiemsions"
import ContentAndImage from "../../../components/templates/contentAndImage/contentAndImage";
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

function AboutUsPL(){
    const { height, width } = useWindowDimensions();
    const isMobile = width <= 767.98;
    return(
        <Page isPadding={true}>
            <PageContent>
                <PageHeader>
                    O nas
                </PageHeader>
                <br />
                <ContentAndImage src="images/home/about-us.jpg">
                    <OurQualitiesGrid>
                        <OurQualitiesGridItem icon={faHelmetSafety}>
                            Fachowa doświadczona ekipa
                        </OurQualitiesGridItem>
                        <OurQualitiesGridItem icon={faShieldHalved}>
                            Solidność
                        </OurQualitiesGridItem>
                        <OurQualitiesGridItem icon={faClockRotateLeft}>
                            Terminowość
                        </OurQualitiesGridItem>
                        <OurQualitiesGridItem icon={faTag}>
                            Atrakcyjna cena
                        </OurQualitiesGridItem>
                    </OurQualitiesGrid>
                    <Paragraph data-aos-once data-aos-duration={1000} data-aos={isMobile ? "fade-left" :"fade-up-left"}>
                        Dzięki zadowoleniu naszych klientów z dobrze i szybko wykonywanych zadań, 
                        nasze usługi stały się znane i cenione na rynku krajowym i zagranicznym
                        Nasze wyspecjalizowane ekipy posiadają wieloletnie doświadczenie w wykonywaniu 
                        przewiertów m.in. pod drogami, torami czy rzekami. 
                    </Paragraph>
                </ContentAndImage>
            </PageContent>
        </Page>
    );
}

export default AboutUsPL;