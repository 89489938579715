import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../styles/atoms/dropDownOption/dropDownOption.css"
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

function DropDownOption({label,children}){
    return(
        <div className="dropdown-option">
            <div className="dropbtn">
                {label}
                <FontAwesomeIcon className="icon" icon={faAngleDown}/>
            </div>
            <div className="dropdown-content">
                {children}
            </div>
        </div>
    );
}

export default DropDownOption;