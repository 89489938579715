import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { PageText } from "../../../components/templates/pageText/pageText";
import { Header } from "../../../components/templates/header/header";
import { PageTilesStyled } from "../../../components/templates/pageTiles/pageTiles.styled";
import PageTile from "../../../components/templates/pageTiles/pageTile";
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import { faRoadBarrier } from '@fortawesome/free-solid-svg-icons'
import { faWater } from '@fortawesome/free-solid-svg-icons'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import AccordionPL from "../../../components/templates/accordion/accordionPL";
import useWindowDimensions from "../../../functions/windowsDiemsions";

function HPSPL(){
    const { width, height } = useWindowDimensions();
    const isDesktop = width > 991.98;

    return(
        <div>
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                Horyzontalne przewierty sterowane
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <Paragraph bold={true}>
                        Przewierty sterowane należą do bezwykopowych metod układania instalacji pod 
                        przeszkodami terenowymi – takimi jak:
                        <ul>
                            <li>drogi,</li>
                            <li>chodniki,</li>
                            <li>chronione tereny zielone,</li>
                            <li>torowiska,</li>
                            <li>rzeki,</li>
                            <li>rowy melioracyjne</li>
                            <li>inne cieki wodne.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Technologia przewiertów sterowanych oparta jest na zasadzie wykonywania otworu i 
                        odpowiedniego poszerzania jego średnicy przy jednoczesnym wyprowadzaniu urobku za 
                        pomocą specjalnie dobranej płuczki wiertniczej, w celu wprowadzenia stosownej rury 
                        osłonowej lub kabla.
                    </Paragraph>
                    <PageText>
                        <Header>Całość procesu składa się z trzech etapów:</Header>
                        <AccordionPL/>
                    </PageText>
                    <PageText>
                        <Header>Wykonywanie płuczki wiertniczej</Header>
                        <br />
                        <Paragraph>
                            Przygotowanie płuczki wiertniczej – polega na dobraniu odpowiedniego bentonitu 
                            (opartego na bazie polimerów organicznych, uzdatnionych dodatkowymi substancjami chemicznymi z 
                            lekką zawartością piasku – środka do odpowiedniego zagęszczania wody tak aby tworzył czasowo trwałą 
                            strukturę zawiesiny o żądanych parametrach – przesycenia z rodzimym gruntem, wypływu oraz 
                            stabilizacji otworu) i zmieszania w odpowiednich proporcjach z wodą, dla celów uzyskania odpowiedniej 
                            ilości i jakości płuczki wiertniczej, która podawana bezpośrednio do wierconego otworu umożliwi 
                            wykonanie pracy.
                        </Paragraph>
                    </PageText>
                    <PageText>
                        <Header>Zastosowanie technologii przewiertów sterowanych pozwala uniknąć:</Header>
                        <PageTilesStyled>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faPersonDigging}>
                                <Paragraph>wykonywania otwartego wykopu, rozpoczynając prace wprost z powierzchni ziemi</Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faRoadBarrier}>
                                <Paragraph>ograniczenia ruchu pojazdów podczas przekraczania szlaków komunikacyjnych</Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faWater}>
                                <Paragraph>naruszania brzegów rzek, wałów przeciwpowodziowych</Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faLeaf}>
                                <Paragraph>dużej ingerencji w środowisko naturalne i architekturę.</Paragraph>
                            </PageTile>
                        </PageTilesStyled>
                    </PageText>
                </PageContent>
            </Page>
        </div>
    );
}
export default HPSPL;