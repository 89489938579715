import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { PageText } from "../../../components/templates/pageText/pageText";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { Header } from "../../../components/templates/header/header";
import { PageTilesStyled } from "../../../components/templates/pageTiles/pageTiles.styled";
import PageTile from "../../../components/templates/pageTiles/pageTile";
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from "../../../functions/windowsDiemsions";

function PPPL(){
    const tileHeight = "100%";
    const tileWidth = "100%";
    const maxWidth = "300px";
    const minHeight = "300px";
    const { width, height } = useWindowDimensions();
    const isDesktop = width > 991.98;

    return(
        <div>
            <MainHeader bgcolor="whitesmoke">Przeciski Podziemne</MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <Paragraph>
                        BEZWYKOPOWA BUDOWA KANAŁÓW Z  RUR OSŁONOWYCH (STALOWYCH, KAMIONKOWYCH).
                    </Paragraph>
                    <br />
                    <Paragraph bold={true}>
                        Przecisk hydrauliczny w porównaniu z metodą wykopu otwartego to technologia bezwykopowa, 
                        w której układanie rur odbywa się pod ziemią bez konieczności wykonywania rozkopu.   
                    </Paragraph>
                    <br />
                    <br />
                    <PageText>
                        <Header>OBSZAR STOSOWANIA METODY BEZWYKOPOWEJ</Header>
                        <Paragraph>
                            <ul>
                                <li>Uzbrojenie nowych terenów budowlanych</li>
                                <li>Przyłącza budynków</li>
                                <li>Przejścia pod drogami, autostradami, liniami kolejowymi, ciekami wodnymi</li>
                                <li>Przejścia pod zabytkami, obiektami budowlanymi</li>
                                <li>Przejścia pod strefami ujęć podziemnych</li>
                            </ul>
                        </Paragraph>
                    </PageText>
                    <PageText>
                    <Header>ZALETY PRZECISKU HYDRAULICZNEGO</Header>
                        <PageTilesStyled>
                            <PageTile isDesktop={isDesktop} icon={faStopwatch} width={tileWidth} height={tileHeight} maxWidth={maxWidth} minHeight={minHeight}>
                                <Paragraph bold={true} border={true}>KRÓTKI CZAS BUDOWY</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>Bezpieczny dla infrastruktury podziemnej</li>
                                        <li>Metoda przyjazna mieszkańcom: brak zakłóceń życia codziennego w miejscu wykonywanych prac</li>
                                    </ul>
                                </Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} icon={faClockRotateLeft} width={tileWidth} height={tileHeight} maxWidth={maxWidth} minHeight={minHeight}>
                                <Paragraph bold={true} border={true}>WYDŁUŻONY OKRES UŻYTKOWANIA</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>Szczególnie wysoka jakość budowlana i bezpieczeństwo kanału</li>
                                        <li>Bezpieczeństwo konstrukcji budowlanej</li>
                                        <li>Wyeliminowane osiadanie kanału</li>
                                    </ul>
                                </Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} icon={faShieldHalved} width={tileWidth} height={tileHeight} maxWidth={maxWidth} minHeight={minHeight}>
                                <Paragraph bold={true} border={true}>OCHRONA NAWIERZCHNI</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>Minimalizacja ingerencji w nawierzchnię</li>
                                        <li>Bezpieczne dla środowiska, ochrona fauny i flory</li>
                                    </ul>
                                </Paragraph>
                            </PageTile>
                        </PageTilesStyled>
                    </PageText>
                </PageContent>
            </Page>
        </div>
    );
}

export default PPPL;