import styled from 'styled-components'


export const Paragraph = styled.div`
    // color: var(--black);
    letter-spacing: 0.2vw;
    li{
        padding: 5px;
    }
    font-weight: ${(props) => props.bold ? "600" : "0"};
    border-bottom: ${(props) => props.border ? "1px" : "0"} solid var(--lightgrey);
`