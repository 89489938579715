import HomeMainEN from './main/mainEN';
import FlagsEN from './flags/flagsEN';
import OurOfferEN from './ourOffer/ourOfferEN';
import AboutUsEN from './aboutUs/aboutUsEN';
import AboutUsSnakeEN from './aboutUsSnake/aboutUsSnakeEN';
import DevicesEN from './devices/devicesEN';
import MachinesEN from './machines/machinesEN';
import BrandsEN from './brands/brandsEN';
import JobOfferHomeEN from './jobOfferHome/jobOfferHomeEN';
import ContactEN from './contact/contactEN';
import { useState } from 'react';
import LoadingScreen from '../../components/organisms/loadingScreen/loadingScreen';

function HomeEN({isNavbarLoaded}) {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const handlePageLoad = () =>{
    setTimeout(() =>{
      setIsPageLoaded(true);
    },1000)
  }

  return (
    <div className="home">
      <LoadingScreen isLoaded={isPageLoaded && isNavbarLoaded}/>
      <HomeMainEN handlePageLoad={handlePageLoad}/>
      <FlagsEN/>
      <OurOfferEN/>
      <AboutUsEN/>
      <AboutUsSnakeEN/>
      <DevicesEN/>
      <MachinesEN/>
      <BrandsEN/>
      <JobOfferHomeEN/>
      <ContactEN/>
    </div>
  );
}

export default HomeEN;
