import { Link, useLocation, useNavigate } from "react-router-dom";

function CustomLink({children, to, refresh, style}){
    const location = useLocation();
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const refreshPage = () =>{
        refresh && location.reload();
    }

    return(
        <Link to={to} onClick={refreshPage} style={{textDecoration: "none"}}>
            {children}
        </Link>
    )
}

export default CustomLink;