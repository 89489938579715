import { useState } from "react";
import "../../../styles/molecules/navbarMenu/navbarMenu.css"
import DropDownOption from "../../atoms/dropDownOption/dropDownOption";
import CustomLink from "../../atoms/customLink/customLink";
function NavbarMenu({language,isMobile, isVisible}){
    const [scroll, setScroll] = useState(false);
    window.addEventListener('scroll',()=> setScroll(window.scrollY > 50));

    const pl = [
        {label: "GALERIA", link: "/pl/gallery"},
        {label: "OFERTA PRACY", link: "/pl/job-offer"},
        {label: "REALIZACJE", link: "/pl/realizations"},
        {label: "KONTAKT", link: "/pl/contact"},
    ]

    const de = [
        {label: "BLIDERGALERIE", link: "/de/gallery"},
        {label: "STELLENANGEBOTE", link: "/de/job-offer"},
        {label: "UNSERE LEISTUNGEN", link: "/de/realizations"},
        {label: "KONTAKT", link: "/de/contact"},
    ]

    const en = [
        {label: "GALLERY", link: "/en/gallery"},
        {label: "JOB OFFER", link: "/en/job-offer"},
        {label: "REALIZATIONS", link: "/en/realizations"},
        {label: "CONTACT", link: "/en/contact"},
    ]

    const pl_offer = [
        {label: "Horyzontalne Przewierty Sterowane", link: "/pl/horyzontalne-przewierty-sterowane"},
        {label: "Przeciski Podziemne", link: "/pl/przeciski-podziemne"},
        {label: "Prace ziemne", link: "/pl/prace-ziemne"},
        {label: "Zgrzewanie rur", link: "/pl/zgrzewanie-rur"},
    ]

    const de_offer = [
        {label: "Gesteuerte Horizontal-Spülbohrungen", link: "/de/horyzontalne-przewierty-sterowane"},
        {label: "Unterirdischer Vortrieb", link: "/de/przeciski-podziemne"},
        {label: "Tiefbau", link: "/de/prace-ziemne"},
        {label: "Rohrschweißen", link: "/de/zgrzewanie-rur"},
    ]

    const en_offer = [
        {label: "Horizontal Control Drills", link: "/en/horyzontalne-przewierty-sterowane"},
        {label: "Underground mines", link: "/en/przeciski-podziemne"},
        {label: "Ground works", link: "/en/prace-ziemne"},
        {label: "Pipe welding", link: "/en/zgrzewanie-rur"},
    ]

    return(
        <div className={!isMobile ? "navbarMenu" : isVisible ? scroll ? "navbarMenu visible scroll": "navbarMenu visible" : "navbarMenu not-visible"}>
            <div className={!isMobile ? "navbarMenu-content" : isVisible ? "navbarMenu-content visible" : "navbarMenu-content not-visible"}>
                <ul>
                    <li>
                    {language === 'pl' ? 
                        <DropDownOption label={"OFERTA"}>
                            <ul>
                                {pl_offer.map((option) => (
                                    <li key={option.link}>
                                        <CustomLink to={option.link}>{option.label}</CustomLink>
                                    </li>
                                ))}
                            </ul>
                        </DropDownOption>
                        : language === 'de' ?
                        <DropDownOption label={"UNSER ANGEBOT"}>
                            <ul>
                                {de_offer.map((option) => (
                                    <li key={option.link}>
                                        <CustomLink to={option.link}>{option.label}</CustomLink>
                                    </li>
                                ))}
                            </ul>
                        </DropDownOption>
                        :
                        <DropDownOption label={"OFFER"}>
                            <ul>
                                {en_offer.map((option) => (
                                    <li key={option.link}>
                                        <CustomLink to={option.link}>{option.label}</CustomLink>
                                    </li>
                                ))}
                            </ul>
                        </DropDownOption>
                    }
                        
                        {/* <DropDownOption label={"OFERTA"}>
                            <ul>
                                <li>
                                    <CustomLink to={"/pl/horyzontalne-przewierty-sterowane"}>Horyzontalne Przewierty Sterowane</CustomLink>
                                </li>
                                <li>
                                    <CustomLink to={"/pl/przeciski-podziemne"}>Przeciski Podziemne</CustomLink>
                                </li>
                                <li>
                                    <CustomLink to={"/pl/prace-ziemne"}>Prace ziemne</CustomLink>
                                </li>
                                <li>
                                    <CustomLink to={"/pl/zgrzewanie-rur"}>Zgrzewanie rur</CustomLink>
                                </li>
                            </ul>
                        </DropDownOption> */}
                    </li>
                    {language === 'pl' ? 
                        pl.map((option) => (
                            <CustomLink key={option.label} to={option.link}>
                                <li>{option.label}</li>
                            </CustomLink>
                        ))
                        : language === 'de' ?
                        de.map((option) => (
                            <CustomLink key={option.label} to={option.link}>
                                <li>{option.label}</li>
                            </CustomLink>
                        ))
                        :
                        en.map((option) => (
                            <CustomLink key={option.label} to={option.link}>
                                <li>{option.label}</li>
                            </CustomLink>
                        ))
                    }
                    
                </ul>
            </div>
        </div>
    );
}

export default NavbarMenu;