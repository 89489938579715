import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import BlueWhiteButton from "../../atoms/blue-white-button/blue-white-button";
import "../../../styles/organisms/form/form.css"
import Input from '../form/formInput';
import { Header } from "../../templates/header/header"
import SuccessfullySent from '../form/successfullySent';
import CheckBox from '../form/checkBox';

function ContactForm ({header, button, inputs, children, mailIDs, checkBoxLabel}){
    const form = useRef();
    const [sent, setSent] = useState(false);
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(mailIDs.service, mailIDs.template, form.current, mailIDs.publicKey)
        .then((result) => {
        }, (error) => {
        });
        setSent(true);
    };

    const [values, setValues] = useState({
        name: "",
        lastname: "",
        company: "",
        email: "",
        subject: "",
        message: "",
    });

    const onChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    return(
        <div className="form-container">
            {sent ? 
                <SuccessfullySent>
                    {children}
                </SuccessfullySent>
            :
                <div>
                    <Header border>{header}</Header>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-wrap">
                            <div className="input">
                                <Input input={inputs[0]} onChange={onChange}/>
                            </div>
                            <div className="input">
                                <Input input={inputs[1]} onChange={onChange}/>
                            </div>
                        </div>
                        <div className="form-wrap">
                            <div className="input">
                                <Input input={inputs[2]} onChange={onChange}/>
                            </div>
                            <div className="input">
                                <Input input={inputs[3]} onChange={onChange}/>
                            </div>
                        </div>
                        <div className="form">
                            <Input input={inputs[4]} onChange={onChange}/>
                            <Input rows={10} isTextarea={true} input={inputs[5]} onChange={onChange}/>
                            <CheckBox checkBoxLabel={checkBoxLabel}/>
                        </div>
                        <BlueWhiteButton><input onChange={onChange} type="submit" value={button} /></BlueWhiteButton>
                    </form>
                </div>
            }
        </div>
    );
}

export default ContactForm;