import "../../../styles/organisms/brandsCarousel/brandsCarousel.css"
import Brand from "./brand";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, FreeMode } from "swiper";
import 'swiper/css';
import 'swiper/css/bundle';
function BrandsCarousel(){
    const brands = [
        {number: 1, link: "https://www.ditchwitch.com/"},
        {number: 2, link: "https://www.baroididp.com/"},
        {number: 3, link: "https://www.vermeer.com/em"},
        {number: 4, link: "https://www.perforator.de/en/"},
        {number: 5, link: "https://www.digital-control.com/"},
        {number: 6, link: "http://www.cebo.com/"},
    ]
    return(
        <div className="brandsCarousel">
            <div className="brandsWrapper">
            <Swiper
                loop={true}
                mousewheel={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                modules={[Autoplay, FreeMode]}
                centeredSlides={false}
                centerInsufficientSlides={true}
                breakpoints={{
                    0: {
                      maxWidth: 250,
                      slidesPerView: 1,
                    },
                    600: {
                        width: 540,
                        slidesPerView: 2,
                      },
                    768: {
                      width: 720,
                      slidesPerView: 3,
                    },
                    992: {
                        width: 960,
                        slidesPerView: 4,
                        spaceBetween: 70,
                    },
                    1200: {
                        width: 1140,
                        slidesPerView: 4,
                        spaceBetween: 120,
                    },
                  }}
            >
                {brands.map((brand) => (
                    <SwiperSlide key={brand.number}>
                        <Brand number={brand.number} link={brand.link}/>
                    </SwiperSlide>
                ))}
            </Swiper>
            </div>
        </div>
    );
}

export default BrandsCarousel;