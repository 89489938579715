import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import { Page } from "../../components/templates/page/page"
import { PageText } from "../../components/templates/pageText/pageText";
import JobOfferForm from "../../components/organisms/jobOfferForm/jobOfferForm";
import { Header } from "../../components/templates/header/header";
import JobOfferItem from "../../components/organisms/jobOfferItem/jobOfferItem";
import JobOfferBox from "../../components/organisms/jobOfferBos/jobOfferBox";
import { faDumbbell } from '@fortawesome/free-solid-svg-icons'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faSnowplow } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import "../../styles/pages/jobOffer/jobOffer.css"
import CustomLink from "../../components/atoms/customLink/customLink";

function JobOfferPL() {
  const mailIDs = {
    service: process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
    template: process.env.REACT_APP_EMAIL_JS_JOB_OFFER_TEMPLATE_PL_ID,
    publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY_ID,
  }
  const inputs = [
    {
      name: "name",
      placeholder: "Imię",
      errorMessage: "Pole wymagane",
      required: true,
      type: "text",
    },
    {
      name: "lastname",
      placeholder: "Nazwisko",
      errorMessage: "Pole wymagane",
      required: true,
      type: "text",
    },
    {
      name: "phone-number",
      placeholder: "Nr telefonu",
      errorMessage: "Podaj prawidłowy numer telefonu (format: 123456789)",
      required: true,
      type: "number",
      pattern: "[0,9]",
      min: "100000000",
    },
    {
      name: "email",
      placeholder: "Email",
      errorMessage: "Podaj prawidłowy adres Email",
      required: true,
      type: "email",
    },
    {
      name: "message",
      placeholder: "Uprawnienia",
      errorMessage: "Pole wymagane",
      required: true,
      type: "text",
    },
    // {
    //   name: "file",
    //   placeholder: "Plik",
    //   errorMessage: "Pole wymagane",
    //   required: true,
    //   type: "file",
    // },
  ];

    return (
      <div>
        <MainHeader bgcolor={"whitesmoke"}>Oferta Pracy</MainHeader>
        <Page bgcolor={"whitesmoke"}>
          <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
            <div className="jobOffer-container">
              <div className="jobOffer-boxes">
                <JobOfferBox header={"Poszukujemy pracowników:"}>
                  <JobOfferItem icon={faDumbbell}>
                    otwartych na nowe wyzwania
                  </JobOfferItem>
                  <JobOfferItem icon={faGraduationCap}>
                    chętnych do nauki
                  </JobOfferItem>
                </JobOfferBox>
                <JobOfferBox header={"Mile widziane:"}>
                  <JobOfferItem icon={faSnowplow}>
                    uprawnienia na koparki
                  </JobOfferItem>
                  <JobOfferItem icon={faTruck}>
                    prawo jazdy kat. C+E.
                  </JobOfferItem>
                </JobOfferBox>
                </div>
                <PageText>
                  <Header>Zadzwoń lub wypełnij formularz. 
                      Oddzwonimy i umówimy się na rozmowę.
                  </Header>
                </PageText>
                <JobOfferForm 
                  checkBoxLabel={
                    <>
                      Zapoznałem się z
                      <CustomLink to={"/pl/private-policy"}> Polityką prywatności </CustomLink>
                       serwisu www.bormat.de
                    </>
                    }
                  mailIDs={mailIDs} button={"Wyślij"} 
                  header={"Formularz kontaktowy"} 
                  inputs={inputs}
                >
                  <p>Twoje zgłoszenie zostało przesłane!</p>
                  <p>Na podanego emaila wysłaliśmy potwierdzenie przesłania zgłoszenia.</p>
                </JobOfferForm>
            </div>
          </PageContent>
        </Page>
      </div>
    );
}
  
export default JobOfferPL;
  