import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const StyledIcon = styled.div`
    font-size: 100px;
    color: var(--blue);
`

const StyledBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 767.98px) {
        flex-direction: column;
        text-align: center;
        padding: 0px 25px 5px 25px;
    }
    
    @media (min-width: 767.99px){
        margin-left: -30px;
        margin-right: -30px;
        & > *{
            padding-left: 30px;
            padding-right: 30px;
        }
        padding: 0px 50px 5px 50px;
    }
`

const StyledText = styled.div`
    p{
        color: var(--blue);
        font-size: 25px;
    }

`

function SuccessfullySent({children}){
    return(
        <StyledBox>
            <StyledIcon>
                <FontAwesomeIcon icon={faEnvelope}/>
            </StyledIcon>
            <StyledText>
                {children}
            </StyledText>
        </StyledBox>
    );
}

export default SuccessfullySent;