import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import useWindowDimensions from "../../../functions/windowsDiemsions";

const Container = styled.div`
    width: 100%;
    display: flex;
    ${(props) => props.isMobile ? 
        "margin-top: -50px;"
        : 
        props.right ?
        "margin-right: -50px;"
        :
        "margin-left: -50px;"
    }
    align-items: center;
    @media (max-width: 767.98px) {
        flex-direction: column;
    }
`

const Content = styled.div`
    ${(props) => props.isMobile ? 
        "margin-top: 50px; margin-bottom: 50px;" 
        : 
        props.right ?
        "margin-left: 0px; margin-right: 50px;"
        :
        "margin-left: 50px; margin-right: -50px;"
    }
    flex: 1;
`

const Image = styled.div`
    ${(props) => props.isMobile ? 
        "margin-bottom: 0px;"
        : 
        props.right ?
        "margin-right: 0px;"
        :
        "margin-left: 50px;"
    }

    @media (min-width: 767.98px) {
        ${(props) => props.horizontal && "max-width: 45%"};
        ${(props) => !props.horizontal && "max-width: 350px;"}
    }

    @media (max-width: 399.99px){
        ${(props) => !props.horizontal && "max-width: 250px"};
    }

    @media (min-width: 399.98px) and (max-width: 767.98px) {
        ${(props) => !props.horizontal && "max-width: 350px"};
    }
    
    img{
        ${(props) => props.horizontal ? "width: 95%" : "height: 95%"};
        display: block;
        ${(props) => !props.horizontal && "max-width: 95%"};
        height: auto;
    }
    position: relative;
    z-index: 100;
    ${(props) => !props.horizontal && "margin-right: 1%"};

    @media (max-width: 500px) {
        :after{
            top: 20px;
            left: 20px;
            width: calc(95% - 20px);
            height: calc(100% - 20px);
        }
    }

    @media (min-width: 500px) {
        :after{
            top: 20px;
            left: 20px;
            width: calc(95% - 15px);
            height: calc(100% - 15px);
        }
    }

    :after{
        content: ' ';
        border: 10px solid var(--${(props) => props.blue ? "blue" : "red"});
        position: absolute;
        z-index: -1;
    }
`

const Icon = styled.div`
    ${(props) => props.isMobile ? 
        "margin-bottom: 0px;"
        : 
        props.right ?
        "margin-right: 0px;"
        :
        "margin-left: 50px;"
    }
    color: var(--${(props) => props.blue ? "blue" : "red"});
    font-size: 85px;
    background-color: var(--white);
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
`

function ContentAndImage({horizontal,handleImageLoaded,right,blue,src,children,icon}){
    const { height, width } = useWindowDimensions();
    const isMobile = width <= 767.98;
    return(
        <Container right={right} isMobile={isMobile}>
            {(!right && !isMobile && src) && 
                <Image data-aos-once  data-aos="fade-right" data-aos-duration={1000} right={right} isMobile={isMobile} blue={blue} horizontal={horizontal}>
                    <img onLoad={handleImageLoaded} src={src} alt="" />
                </Image>
            }
            {(!right && !isMobile && icon) && 
                <Icon data-aos-once  data-aos="fade-right" data-aos-duration={1000} right={right} isMobile={isMobile} blue={blue}>
                    <FontAwesomeIcon icon={icon}/>
                </Icon>
            }
            <Content right={right} isMobile={isMobile}>
                {children}
            </Content>
            {(right || isMobile)  && src &&
                <Image data-aos-once  data-aos="fade-left" data-aos-duration={1000} right={right} isMobile={isMobile} blue={blue} horizontal={horizontal}>
                    <img onLoad={handleImageLoaded} src={src} alt="" />
                </Image>
            }
            {(right || isMobile)  && icon &&
                <Icon data-aos-once  data-aos="fade-left" data-aos-duration={1000} right={right} isMobile={isMobile} blue={blue}>
                    <FontAwesomeIcon icon={icon}/>
                </Icon>
            }
        </Container>
    )
}

export default ContentAndImage;