import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { PageText } from "../../../components/templates/pageText/pageText";
import { Page } from "../../../components/templates/page/page";
import AboutUsSnakeItem from "../../../components/organisms/aboutUsSnakeItem/AboutUsSnakeItem";
import styled from "styled-components";
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faDroplet } from '@fortawesome/free-solid-svg-icons'
import { faFaucetDrip } from '@fortawesome/free-solid-svg-icons'
import { faFireFlameCurved } from '@fortawesome/free-solid-svg-icons'
import { faFireFlameSimple } from '@fortawesome/free-solid-svg-icons'
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons'
import { Header } from "../../../components/templates/header/header";
import SnakePath from "./SnakePath";
import "aos/dist/aos.css"

const SnakeContainer = styled.div`
margin: auto;    
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        width: 720px;
    }
    
    @media (min-width: 991.98px) and (max-width: 1199.98px) {
        width: 720px;
    }
    
    @media (min-width: 1199.99px) {
        width: 720px;
    }
`

const Snake = styled.div`
    display: flex;
    flex-direction: column;
    margin: -50px 0;
`

function AboutUsSnakeEN(){
    return(
        <Page>
            <PageContent>
                <SnakeContainer>
                    <PageText>
                        <Header data-aos-once data-aos-duration={1000} data-aos="fade-right">
                            Nasze technologie znajdą zastosowanie na potrzeby budowy sieci:
                        </Header>
                    </PageText>
                    <br />
                    <br />
                    <Snake>
                        <AboutUsSnakeItem icon={faBolt}>
                            energetycznej
                        </AboutUsSnakeItem>
                        <SnakePath/>
                        <AboutUsSnakeItem icon={faPhone} right>
                            telekomunikacyjnej
                        </AboutUsSnakeItem>
                        <SnakePath upsideDown/>
                        <AboutUsSnakeItem icon={faDroplet}>
                            wodociągowej
                        </AboutUsSnakeItem>
                        <SnakePath/>
                        <AboutUsSnakeItem icon={faFaucetDrip} right>
                            kanalizacyjnej (grawitacyjnej i tłocznej)
                        </AboutUsSnakeItem>
                        <SnakePath upsideDown/>
                        <AboutUsSnakeItem icon={faFireFlameCurved}>
                            ciepłowniczej
                        </AboutUsSnakeItem>
                        <SnakePath/>
                        <AboutUsSnakeItem icon={faFireFlameSimple} right>
                            gazowej
                        </AboutUsSnakeItem>
                        <SnakePath upsideDown/>
                        <AboutUsSnakeItem icon={faWaveSquare}>
                            rurociągów technologicznych i przemysłowych
                        </AboutUsSnakeItem>
                    </Snake>
                </SnakeContainer>
            </PageContent>
        </Page>
    );
}

export default AboutUsSnakeEN;