import styled from 'styled-components'

export const ContactGrid = styled.div`
    @media (max-width: 767.98px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -25px;
        margin-bottom: -25px;
        & > *{
            padding: 25px;
        }
        padding: 30px 0px;
    }
    
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: -30px;
        margin-bottom: -30px;
        & > *{
            padding: 30px;
        }
        padding: 30px 0px;
    }
    
    @media (min-width: 991.98px) and (max-width: 1199.98px) {
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0px;
    }
    
    @media (min-width: 1199.99px) {
        justify-content: space-between;
        padding: 30px 0px;
    }

    display: flex;
    border-bottom: 1px solid var(--lightgrey);
    border-top: 1px solid var(--lightgrey);
`