import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { PageText } from "../../../components/templates/pageText/pageText";
import { PageContainer } from "../../../components/templates/pageContainer/pageContainer"

function ZREN(){
    return(
        <PageContainer bgcolor="whitesmoke">
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                Pipe welding
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <Paragraph>
                        Oferujemy Państwu <strong>doczołowe zgrzewanie</strong> rur z tworzyw sztucznych PE i PP w zakresach od 90mm do 315 mm. 
                        Dysponujemy wysokiej klasy automatycznymi zgrzewarkami doczołowymi.
                        Trwałość montowanych rurociągów gwarantują wysokie kwalifikacje pracowników.
                    </Paragraph>
                </PageContent>
            </Page>
        </PageContainer>
    );
}
export default ZREN;