import "../../../styles/atoms/white-blue-button/white-blue-button.css";

function WhiteBlueButton ({children}){
    return(
        <button className="white-blue-button">
            {children}
        </button>
    );
}

export default WhiteBlueButton;