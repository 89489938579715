import "../../../styles/atoms/animatedArrow/animatedArrow.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
function AnimatedArrow({hover}){
    return(
        <div className="animatedArrow">
            <FontAwesomeIcon className={hover ? "arrow-hover" : "arrow"} icon={faArrowRightLong}/>
        </div>
    );
}

export default AnimatedArrow;