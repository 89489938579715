import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { PageText } from "../../../components/templates/pageText/pageText";
import LoadingScreen from "../../../components/organisms/loadingScreen/loadingScreen";
import { useState } from "react";
import ContentAndImage from "../../../components/templates/contentAndImage/contentAndImage";
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

function PZDE(){
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const handleImageLoaded = () => {
        setIsImageLoaded(true);
    }
    
    return(
        <div>
            <LoadingScreen isLoaded={isImageLoaded}/>
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                TIEFBAU
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent>
                    <ContentAndImage blue right horizontal={true} handleImageLoaded={handleImageLoaded} src={"/images/offer/prace-ziemne.jpg"}>
                        <Paragraph data-aos-once  data-aos="fade-right" data-aos-duration={1000}>
                            Wir setzen auch unsere eigenen Bagger und die dazugehörige Ausrüstung für die Baumaßnahmen 
                            ein.
                        </Paragraph>
                        <PageText>
                            <Paragraph data-aos-once  data-aos="fade-right" data-aos-duration={1000}>
                                Unsere Logistik- und Transportbasis ermöglicht es uns, im ganzen Bundesgebiet hinaus 
                                zu operieren.
                            </Paragraph>
                        </PageText>
                    </ContentAndImage>
                   <PageText padding="90px 0">
                        <ContentAndImage blue horizontal={true} handleImageLoaded={handleImageLoaded} icon={faLeaf}>
                                <Paragraph data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                                    Alle Tiefbauarbeiten werden von unserem Unternehmen mit professionellen Geräten 
                                    und Spezialwerkzeugen durchgeführt. Wir versuchen, das bestehende Ökosystem an 
                                    dem Ort, an dem die Arbeiten durchgeführt werden, so wenig wie möglich zu 
                                    beeinträchtigen, so dass sie umweltfreundlich sind. Wir sind uns der Bedeutung 
                                    des Gleichgewichts des Ökosystems bewusst und bemühen uns daher um die 
                                    Aufrechterhaltung des ursprünglichen Zustands.
                                </Paragraph>
                        </ContentAndImage>
                   </PageText>
                    <ContentAndImage blue horizontal={true} handleImageLoaded={handleImageLoaded} icon={faClipboardList}>
                        <Paragraph data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                            Tiefbauarbeiten, darunter Vorbereitungs-, Kanalisations-, Wasserversorgungs- oder 
                            Aushubarbeiten, werden nach den Anweisungen des Auftraggebers entsprechend dem vorgelegten 
                            Projekt und den Erfordernissen für die weiteren Arbeiten - Installation oder Bau - durchgeführt.
                        </Paragraph>
                    </ContentAndImage>
                </PageContent>
            </Page>
        </div>
    );
}
export default PZDE;