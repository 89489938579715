import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import "aos/dist/aos.css"
import ContentAndImage from "../../../components/templates/contentAndImage/contentAndImage";

function DevicesDE(){
    return(
        <Page>
            <PageContent>
                <ContentAndImage right horizontal={false} src={"/images/home/devices.jpg"}>
                    <Paragraph data-aos-once data-aos-duration={1000} data-aos="fade-down">
                        Für die Durchführung der uns zugewiesenen Arbeiten setzen wir elektronische Geräte 
                        zur Erkennung von Hindernissen im Untergrund und moderne Navigationstechnik ein.
                    </Paragraph>
                    <Paragraph data-aos-once data-aos-duration={1000} data-aos="fade-up">
                        Dies ermöglicht uns, die volle Kontrolle über die Bohrtrasse zu haben und dabei u.a. 
                        die fremden Leitungen und Kabel zu umgehen. Wir verfügen auch über eine Haftpflichtversicherung 
                        für Schäden, die während der Erdarbeiten an Dritten entstehen könnten.
                    </Paragraph>
                </ContentAndImage>
            </PageContent>
        </Page>
    );
}

export default DevicesDE;