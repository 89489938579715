import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../styles/molecules/ourQualitiesGrid/ourQualitiesGridItem.css"
function OurQualitiesGridItem({icon,children}){
    return(
        <div data-aos-once data-aos="zoom-in-left" data-aos-duration={1000} className="ourQualitiesGridItem">
            <div className="icon">
                <FontAwesomeIcon icon={icon}/>
            </div>
            <div className="text">
                {children}
            </div>
        </div>
    );
}

export default OurQualitiesGridItem;