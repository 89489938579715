import styled from 'styled-components'

export const PageContent = styled.div`
    @media (max-width: 767.98px) {
        max-width: 540px;
        padding: 0% 5%;
    }
      
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        width: 720px;
        padding: 0% 5%;
    }
        
    @media (min-width: 991.99px) and (max-width: 1199.98px) {
        width: 960px;
    }
        
    @media (min-width: 1199.99px) {
        width: 1140px;
    }
  `