import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { PageText } from "../../../components/templates/pageText/pageText";
import { PageContainer } from "../../../components/templates/pageContainer/pageContainer"

function ZRDE(){
    return(
        <PageContainer bgcolor="whitesmoke">
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                ROHRSCHWEIßEN
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <Paragraph>
                        Wir bieten Ihnen <strong>Stumpfschweißen</strong> von PE- und PP-Kunststoffrohren im Bereich 
                        von 90 mm bis 315 mm. Wir verfügen über hochmoderne automatische Stumpfschweißmaschinen. 
                        Die Langlebigkeit der montierten Rohrleitungen wird durch die hohe Qualifikation unserer 
                        Mitarbeiter gewährleistet.
                    </Paragraph>
                </PageContent>
            </Page>
        </PageContainer>
    );
}
export default ZRDE;