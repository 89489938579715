import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledIcon = styled.div`
@media (max-width: 429.98px) {
    font-size: 20px;
    min-width: 30px;
    min-height: 30px;
}
@media (min-width: 429.98px) {
    font-size: 30px;
    min-width: 50px;
    min-height: 50px;
}
-webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
padding: 10px;
color: var(--red);
border: 2px solid var(--red);
display: flex;
align-items: center;
justify-content: center;
order: ${(props) => props.right ? 2 : 1};
`

const StyledBox = styled.div`
margin: 50px 0;
display: grid;
grid-template-columns: auto auto;
align-items: center;
margin-left: ${(props) => props.right ? "auto" : 0};

@media (max-width: 429.98px) {
    gap: 15px;
    grid-gap: 15px;
    width: 260px;
    justify-content: ${(props) => props.right ? "right" : "left"};
}

@media (min-width: 429.99px) {
    gap: 30px;
    grid-gap: 30px;
    width: 320px;
    justify-content: ${(props) => props.right ? "right" : "left"};
}
`

const StyledText = styled.div`
order: ${(props) => props.right ? 1 : 2};

p{
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    text-align: ${(props) => props.right ? "right" : "left"};
}
`

function AboutUsSnakeItem({children, icon, right}){
    return(
        <StyledBox right={right}>
            <StyledIcon right={right}>
                <FontAwesomeIcon icon={icon}/>
            </StyledIcon>
            <StyledText right={right}>
                <p data-aos-once data-aos-duration={1000} data-aos={right ? "fade-right" : "fade-left"}>
                    {children}
                </p>
            </StyledText>
        </StyledBox>
    );
}

export default AboutUsSnakeItem;