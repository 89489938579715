import styled from 'styled-components'

export const OurQualitiesGrid = styled.div`
    @media (max-width: 1199.98px) {
        flex-wrap: wrap;
    }
    justify-content: space-between;
    padding: 30px 0px;
    margin-bottom: 30px;
    display: flex;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    letter-spacing: 4px;
`