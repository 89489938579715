import "../../../styles/pages/home/contact/contact.css"
import { ContactGrid } from "../../../components/organisms/contactSection/contactGrid.styled";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import ContactItem from "../../../components/organisms/contactSection/contactItem";
import { Link } from "react-router-dom";
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-solid-svg-icons'
import "aos/dist/aos.css"
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

function ContactPL(){
    return(
        <Page isPadding={true}>
            <PageContent>
                <PageHeader>
                    Skontaktuj się z nami!
                </PageHeader>
                <br />
                <ContactGrid>
                    <ContactItem icon={faPhone} arrow={false}>
                    <p>+48 24 366 65 02</p>
                    <p>+49 151 27646862</p>
                    <p>+49 151 25988034</p>
                    <p>+49 151 54709101</p>
                    </ContactItem>
                    <ContactItem icon={faEnvelope} arrow={false}>
                        <p>mboerner@bormat.com.pl</p>
                    </ContactItem>
                    <Link to={"/pl/contact"}>
                        <ContactItem icon={faMessage} arrow={true}>
                            <p>inne formy kontaktu</p>
                        </ContactItem>
                    </Link>
                </ContactGrid>
            </PageContent>
        </Page>
    );
}

export default ContactPL;