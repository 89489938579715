import "../../../styles/pages/home/main/main.css"
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import { StyledMainHeader }from "../../../components/templates/mainHeader/mainHeader.styled"
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import "aos/dist/aos.css"
import { useEffect } from "react";
import styled from "styled-components";

const PhotoSource = styled.div`
    position: absolute;
    z-index: 10000;
    right: 20px;
    bottom: 20px;
    color: var(--white);
    font-size: 10px;
    opacity: 0.7;
    a{
        color: var(--white);
    }
`

function HomeMainDE({handlePageLoad}){
    useEffect(() => {
        handlePageLoad();
    })
    
     return(
        <div className="homeMain">
            <PhotoSource>
                photo: {" "}
                <a href="https://www.ditchwitch.com/">ditchwitch.com</a>
            </PhotoSource>
            <PageContent>
                <div className="mainText">
                    <header>
                        <StyledMainHeader data-aos-delay={1000} data-aos-duration={1000} data-aos="fade-right">
                            GESTEUERTE HORIZONTAL-SPÜLBOHRUNGEN
                        </StyledMainHeader>
                    </header>
                    <div className="paragraph">
                        <Paragraph data-aos-delay={1000} data-aos-duration={1000} data-aos="fade-left">
                            Wir bieten unseren Kunden die Ausführung von unterirdischen Installationen unter Anwendung 
                            modernster grabenloser Technologien wie Bohr- und Vortriebsverfahren.
                        </Paragraph>
                        <Paragraph data-aos-delay={1000} data-aos-duration={1000}  data-aos="fade-left">
                            Ihr Einsatz vermeidet die Einschränkung des Fahrzeugverkehrs beim Überqueren von 
                            Verkehrswegen, die Beeinträchtigung von Flussufern, Seen und Deichen und minimiert 
                            gleichzeitig die Eingriffe in die natürliche Umwelt.
                        </Paragraph>
                    </div>
                </div>
            </PageContent>
        </div>
    );
}

export default HomeMainDE;