import "../../../styles/organisms/contactSection/contactItem.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import AnimatedArrow from "../../atoms/animatedArrow/animatedArrow";
import ContactBoxItem from "../../atoms/contactBoxItem/contactBoxItem";
import "aos/dist/aos.css"

function ContactItem({icon, children, arrow}){
    const [hover, setHover] = useState(false);
    const handleMouseEnter = () =>{
        setHover(true);
    }

    const handleMouseLeave = () =>{
        setHover(false);
    }
    
    return(
         <div className="contact-item-container" data-aos-once  data-aos="flip-left" data-aos-duration={1000} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <ContactBoxItem white hover={arrow ? hover : false}>
            <div className="contact-item-content">
                    <div className="icon">
                        <FontAwesomeIcon icon={icon}/>
                    </div>
                    <div className="text">
                        {children}
                    </div>
                    {arrow === true ? <AnimatedArrow hover={hover}/> : <></>}
                </div>
            </ContactBoxItem>
        </div>
    );
}

export default ContactItem;