import "../../../styles/atoms/blue-white-button/blue-white-button.css";

function BlueWhiteButton ({children}){
    return(
        <button className="blue-white-button">
            {children}
        </button>
    );
}

export default BlueWhiteButton;