import styled from 'styled-components'

const StyledLabel = styled.div`
        display: flex;
        span{
            color: var(--red);
            padding-left: 3px;
        }

        p{
            margin: 0;
        }
        margin-bottom: 15px;
        font-weight: 600;
    `

function InputLabel({label, children, required}){

    return(
        <div>
            <StyledLabel>
                <p>{label}</p>
                {required && <span>*</span>}
            </StyledLabel>
            {children}
        </div>
    );
}

export default InputLabel;