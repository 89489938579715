import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import "aos/dist/aos.css"
import ContentAndImage from "../../../components/templates/contentAndImage/contentAndImage";

function DevicesEN(){
    return(
        <Page>
            <PageContent>
                <ContentAndImage right horizontal={false} src={"/images/home/devices.jpg"}>
                    <Paragraph data-aos-once data-aos-duration={1000} data-aos="fade-down">
                        Przy zleconych pracach wykorzystujemy elektroniczne urządzenia wykrywające wszelkie podziemne 
                        przeszkody oraz nowoczesne technologie nawigujące.
                    </Paragraph>
                    <Paragraph data-aos-once data-aos-duration={1000} data-aos="fade-up">
                        Dzięki temu mamy możliwość pełnej kontroli 
                        trasy przewiertów, omijając miejsca występowania m.in. kabli i rurociągów. 
                        Posiadamy również ubezpieczenie od odpowiedzialności materialnej za szkody wyrządzone osobom 
                        trzecim podczas robot ziemnych.
                    </Paragraph>
                </ContentAndImage>
            </PageContent>
        </Page>
    );
}

export default DevicesEN;