import "../../../styles/organisms/navbar/navbar.css"
import LogoNavbar from "../../atoms/logoNavbar/logoNavbar";
import NavbarMenu from "../../molecules/navbarMenu/navbarMenu";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom';
import CustomLink from "../../atoms/customLink/customLink";
import NavbarLanguages from "../../molecules/navbarLanguages/navbarLanguages";

function Navbar({language, setLanguage, handleNavbarLoad}){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 991.98;
    const [isVisible, setIsVisible] = useState(false);
    const {pathname} = useLocation();
    const navigate = useNavigate();

    const changeLanguage = (newLanguage) =>{
        setLanguage(newLanguage);
        let newPath = "/" + newLanguage + pathname.slice(3);
        navigate(newPath);
    }

    useEffect(() => {
        setIsVisible(false); 
    }, [ pathname ]);
  

    const onClickBars = () =>{
        setIsVisible(currentVisible => !currentVisible);
    }

    useEffect(() => {
        function handleResize() {
          setWindowWidth(window.innerWidth);
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);
      
    const [scroll, setScroll] = useState(false);
    window.addEventListener('scroll',()=> setScroll(window.scrollY > 50));

    return(
        <div className={scroll ? "navbar scroll" : "navbar"}>
            {!isMobile ? 
                <div className="navbar-content">
                    <div className="background-container">
                        <div className={scroll ? "background shadow" : "background"}/>
                    </div>
                    <CustomLink refresh to={"/"+language}>
                        <LogoNavbar handleNavbarLoad={handleNavbarLoad} isMobile={isMobile}/>
                    </CustomLink>
                    
                    <NavbarMenu language={language} isMobile={isMobile} isVisible={isVisible}/>
                    <NavbarLanguages language={language} changeLanguage={changeLanguage}/>
                </div>:
                <div className="navbar-content-mobile">
                    <div className="navbar-content-icons">
                        <CustomLink refresh to={"/"+language}>
                            <LogoNavbar handleNavbarLoad={handleNavbarLoad} isMobile={isMobile}/>
                        </CustomLink>
                        <NavbarLanguages language={language} changeLanguage={changeLanguage}/>
                        <div className="bars" isvisible={isVisible.toString()}>
                            <FontAwesomeIcon onClick={onClickBars} icon={isVisible ? faXmark : faBars}/>
                        </div>
                    </div>
                    <NavbarMenu language={language} isMobile={isMobile} isVisible={isVisible}/>
                </div>
            }         
        </div>
    );
}

export default Navbar;