import styled from 'styled-components'
import { Header } from '../../templates/header/header';

const StyledBox = styled.div`
    padding-bottom: 20px;
}
`

function JobOfferBox({children, header}){
    return(
        <StyledBox>
            <Header>{header}</Header>
            {children}
        </StyledBox>
    );
}

export default JobOfferBox;