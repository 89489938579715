import styled from "styled-components";
import SquareLoader from "react-spinners/SquareLoader";

const StyledLoadingScreen = styled.div`
    visibility: ${(props) => props.isLoaded ? "hidden" : "visible"};
    opacity: ${(props) => props.isLoaded ? "0" : "100%"};
    transition: opacity 0.5s ease, visibility 0.5s ease;
    z-index: 1000;
    top: 0;
    position: fixed;
    background-color: var(--white);
    font-size: 60px;
    color: var(--blue);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
`

function LoadingScreen({isLoaded}){
    return(
        <StyledLoadingScreen isLoaded={isLoaded}>
            <SquareLoader
                size={50}
                speedMultiplier={1}
                color={"#083d77"}
            />
        </StyledLoadingScreen>
    )
}

export default LoadingScreen;