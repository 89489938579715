import BrandsCarousel from "../../../components/organisms/brandsCarousel/brandsCarousel";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import "aos/dist/aos.css"
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

function BrandsDE(){
    return(
        <Page>
            <PageContent>
                <PageHeader>
                    DER ERFOLG DES UNTERNEHMENS LIEGT IN DER ZUSAMMENARBEIT MIT DEN BESTEN
                </PageHeader>
                <BrandsCarousel/>
            </PageContent>
        </Page>
    );
}

export default BrandsDE;