import HomeMainPL from './main/mainPL';
import FlagsPL from './flags/flagsPL';
import OurOfferPL from './ourOffer/ourOfferPL';
import AboutUsPL from './aboutUs/aboutUsPL';
import AboutUsSnakePL from './aboutUsSnake/aboutUsSnakePL';
import DevicesPL from './devices/devicesPL';
import MachinesPL from './machines/machinesPL';
import BrandsPL from './brands/brandsPL';
import JobOfferHomePL from './jobOfferHome/jobOfferHomePL';
import ContactPL from './contact/contactPL';
import LoadingScreen from '../../components/organisms/loadingScreen/loadingScreen';
import { useState } from 'react';

function HomePL({isNavbarLoaded}) {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const handlePageLoad = () =>{
    setTimeout(() =>{
      setIsPageLoaded(true);
    },1000)
  }

  return (
    <div className="home">
      <LoadingScreen isLoaded={isPageLoaded && isNavbarLoaded}/>
      <HomeMainPL handlePageLoad={handlePageLoad}/>
      <FlagsPL/>
      <OurOfferPL/>
      <AboutUsPL/>
      <AboutUsSnakePL/>
      <DevicesPL/>
      <MachinesPL/>
      <BrandsPL/>
      <JobOfferHomePL/>
      <ContactPL/>
    </div>
  );
}

export default HomePL;
