import styled from 'styled-components'
import ContactBoxItem from '../../atoms/contactBoxItem/contactBoxItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledIcon = styled.div`
font-size: 70px;
padding: 10px;
position: absolute;
top: 57px;
left: 77px;
z-index: 3;
color: var(--red);
width: 100px;
height: 100px;
display: flex;
justify-content: center;
align-items: center;
`

const StyledBox = styled.div`
display: grid;
flex: 1;
align-items: center;

@media (max-width: 429.98px) {
    grid-template-rows: auto auto;
    justify-content: center;
    gap: 30px;
    grid-gap: 30px;
}

@media (min-width: 429.98px) {
    grid-template-columns: 250px auto;
    gap: 20px;
    grid-gap: 30px;
}
`

const StyledText = styled.div`
@media (min-width: 429.98px) {
    margin-left: 18px;
}

p{
    margin: 0;
    font-weight: 500;
}
`

function ContactBox({children, icon}){
    return(
        <StyledBox>
            <ContactBoxItem>
                <StyledIcon>
                    <FontAwesomeIcon icon={icon}/>
                </StyledIcon>
            </ContactBoxItem>
            <StyledText>
                {children}
            </StyledText>
        </StyledBox>
    );
}

export default ContactBox;