import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import { Page } from "../../components/templates/page/page"
import "../../styles/pages/contact/contactPage.css"
import ContactBox from "../../components/organisms/contactBox/contactBox";
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import ContactForm from "../../components/organisms/contactForm/contactForm";
import CustomLink from "../../components/atoms/customLink/customLink";

function ContactDE() {
  const mailIDs = {
    service: process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
    template: process.env.REACT_APP_EMAIL_JS_CONTACT_TEMPLATE_PL_ID,
    publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY_ID,
  }

  const inputs = [
    {
      name: "name",
      placeholder: "Vorname",
      errorMessage: "Pflichtfeld",
      required: true,
      type: "text",
    },
    {
      name: "lastname",
      placeholder: "Name",
      errorMessage: "Pflichtfeld",
      required: true,
      type: "text",
    },
    {
      name: "company",
      placeholder: "Firma",
      errorMessage: "Pflichtfeld",
      required: false,
      type: "text",
    },
    {
      name: "email",
      placeholder: "Email-Adresse",
      errorMessage: "Eine korrekte Email-Adresse eingeben",
      required: true,
      type: "email",
    },
    {
      name: "subject",
      placeholder: "Betreff",
      errorMessage: "",
      required: false,
      type: "text",
    },
    {
      name: "message",
      placeholder: "Ihre Nachricht an uns",
      errorMessage: "Pflichtfeld",
      required: true,
      type: "text",
    },
  ];

    return (
      <div>
        <MainHeader bgcolor={"whitesmoke"}>Kontakt</MainHeader>
        <Page bgcolor={"whitesmoke"}>
          <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
            <div className="contact-container">
              <div className="contact-boxes">
                  <ContactBox icon={faPhone}>
                    <p>tel/fax:</p>
                    <p>+48 24 366 65 02</p>
                    <br />
                    <p>mobile:</p>
                    <p>+49 151 27646862</p>
                    <p>+49 151 25988034</p>
                    <p>+49 151 54709101</p>
                  </ContactBox>
                  <ContactBox icon={faLocationDot}>
                    <p>Bormat Sp. z.o.o.</p>
                    <p>ul. Jaworowa 19</p>
                    <p>09-400 Maszewo Duże</p>
                    <br />
                    <p>Bormat GmbH</p>
                    <p>Störtebekerweg 8a</p>
                    <p>23669 Timmendorfer Strand</p>
                  </ContactBox>
                </div>
                <ContactForm 
                  checkBoxLabel={
                    <>
                      Hiermit bestätige ich, dass ich die 
                      <CustomLink to={"/de/private-policy"}> Datenschutzbestimmungen </CustomLink>
                      von www.bormat.de zur Kenntnis genommen habe
                    </>
                    } 
                  mailIDs={mailIDs} button={"Senden"} 
                  header={"Kontaktformular"} 
                  inputs={inputs}
                >
                  <p>Ihre Nachricht wurde gesendet!</p>
                  <p>Wir haben eine Bestätigung Ihrer Nachricht an die angegebene E-Mail-Adresse geschickt.</p>
                </ContactForm>
            </div>
          </PageContent>
        </Page>
      </div>
    );
}
  
export default ContactDE;
  