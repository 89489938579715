import React from "react";
import { Page } from "../../components/templates/page/page";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { PageText } from "../../components/templates/pageText/pageText";
import styled from "styled-components";

const StyledOl = styled.ol`
    margin: 15px 0;
    p{
        margin-left: 15px;
    }
    li{
        padding-top: ${(props) => props.type === "I" ? "15px" : "5px"};
    }
    letter-spacing: 0.2vw;
`

const BoldLi = styled.li`
    font-weight: bold;
`

const SmallerHeader = styled.div`
@media (max-width: 499.99px){
    font-size: 6vw !important;
}
@media (min-width: 499.99px) and (max-width: 767.98px) {
    font-size: 30px !important;
}
`

const Container = styled.div`
@media (max-width: 349.99px){
    p{
        font-size: 4vw !important;
    }
}
`

function PrivacyPolicyDE(){
    return(
        <Container>
            <MainHeader bgcolor="whitesmoke" image="">
                <SmallerHeader>
                    DATENSCHUTZERKLÄRUNG VON www.bormat.de
                </SmallerHeader>
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <p>
                        Der Schutz der Privatsphäre der Benutzer ist <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ 
                        ODPOWIEDZIALNOŚCIĄ</strong> besonders wichtig. Aus diesem Grund wird den Benutzern der Website  
                        <strong> www.bormat.de</strong> ein hohes Maß an Schutz der Privatsphäre garantiert.  
                        <strong> „BORMAT” SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ</strong> ist als Administrator der 
                        Datenverarbeitung um die Sicherheit der von den Benutzern bereitgestellten Daten besorgt.
                    </p>
                    <p>
                        Es ist auch das Ziel des Administrators, die Benutzer ordnungsgemäß über ihre Rechte und Pflichten 
                        im Zusammenhang mit der Verarbeitung personenbezogener Daten zu informieren, insbesondere im 
                        Hinblick auf den Inhalt der Datenschutzbestimmungen, die in der Verordnung (EU) 2016/679 des 
                        Europäischen Parlaments und des Rates vom 27.04.2016 zum Schutz natürlicher Personen bei der 
                        Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 
                        95/46/EG (im Folgenden "RODO") festgelegt sind. Um die Privatsphäre der Benutzer der Website                      
                        zu schützen, informiert der Administrator in diesem Dokument über die Rechtsgrundlage für die 
                        Verarbeitung personenbezogener Daten, die von den Benutzern im Zusammenhang mit der Nutzung der 
                        Website <strong> www.bormat.de</strong> (im Folgenden als "Website" bezeichnet) zur Verfügung gestellt 
                        werden, über die Methoden der Erhebung, Verarbeitung und des Schutzes personenbezogener 
                        Daten sowie über die Rechte der Benutzer.
                    </p>
                    <p>
                        Ein Benutzer ist jede natürliche Person, deren Daten betroffen sind, die die Website  
                        <strong> www.bormat.de</strong> oder die über die Website verfügbaren elektronischen Dienste nutzt.
                    </p>
                    <p>
                        Der Administrator der vom Benutzer auf der Website <strong> www.bormat.de </strong>
                        bereitgestellten personenbezogenen Daten ist <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ 
                        ODPOWIEDZIALNOŚCIĄ, Jaworowa 19 09-400 Maszewo Duże, NIP 7742975568,</strong> 
                        (nachstehend "Administrator" genannt).
                    </p>
                    <StyledOl type="I">
                        <BoldLi>
                            <p>BENUTZERZUSTIMMUNG</p>
                        </BoldLi>
                        <p>
                            Die Benutzung von <strong> www.bormat.de </strong>durch den Benutzer bedeutet, dass der 
                            Benutzer akzeptiert, dass der Administrator personenbezogene Daten in Übereinstimmung mit 
                            dieser Datenschutzrichtlinie sammelt und verwendet.
                        </p>
                        <p>
                            Die personenbezogenen Daten des Service-Benutzers werden vom Administrator auf der Grundlage 
                            der Zustimmung des Benutzers und, in bestimmten hier beschriebenen Fällen, im Rahmen des 
                            berechtigten Interesses des Administrators verarbeitet. Der Benutzer hat das Recht, seine 
                            zuvor erteilte Zustimmung jederzeit zu widerrufen. Der Widerruf der Einwilligung berührt 
                            nicht die Rechtmäßigkeit der Verarbeitung, die auf der Grundlage der Einwilligung vor deren 
                            Widerruf erfolgt ist.
                        </p>
                        <p>
                            Sollte es eine Änderung dieser Datenschutzrichtlinie geben und der Benutzer setzt die 
                            Nutzung des Services fort, so gilt dies als Zustimmung zu den aktuellen Bedingungen der 
                            Datenschutzrichtlinie.
                        </p>
                        <BoldLi>
                            <p>VOM ADMINISTRATOR VERARBEITETE PERSONENBEZOGENE DATEN</p>
                        </BoldLi>
                        <StyledOl>
                            <BoldLi>
                                <p>Art der Beschaffung personenbezogener Daten</p>
                            </BoldLi>
                            <p>
                                Der Administrator erhält personenbezogene Daten direkt vom Benutzer über die Website, 
                                indem der Benutzer das auf der Website verfügbare Kontaktformular ausfüllt und eine 
                                Nachricht an den Administrator schickt.
                            </p>
                            <p>
                                Die Angabe der im Kontaktformular enthaltenen persönlichen Daten ist freiwillig.
                            </p>
                            <BoldLi>
                                <p>Arten der verarbeiteten personenbezogenen Daten</p>
                            </BoldLi>
                            <p>
                                Der Administrator sammelt die folgenden personenbezogenen Daten des Benutzers über 
                                den Service:
                            </p>
                            <StyledOl>
                                <li>Vor- und Nachname;</li>
                                <li>E-Mail- Adresse;</li>
                                <li>Telefonnummer;</li>
                                <li>Inhalt der Nachricht;</li>
                                <li>Betreff der Nachricht;</li>
                                <li>Name des Unternehmens.</li>
                            </StyledOl>
                        </StyledOl>
                        <BoldLi>
                            <p>ZWECKE DER VERARBEITUNG PERSONENBEZOGENER DATEN</p>
                        </BoldLi>
                        <p>
                            Die Art und Weise, in der der Administrator die Daten des Benutzers verarbeitet, 
                            hängt von der Art und Weise ab, in der der Benutzer die Website und die darin verfügbaren 
                            Funktionalitäten nutzt. Der Administrator verarbeitet die personenbezogenen Daten des 
                            Benutzers zu den folgenden Zwecken:
                        </p>
                        <StyledOl>
                            <BoldLi>
                                <p>Mit dem Benutzer zu kommunizieren.</p>
                            </BoldLi>
                            <p>
                                Der Administrator verwendet die personenbezogenen Daten des Benutzers, um mit dem 
                                Benutzer auf personalisierte Weise zu kommunizieren. Die Informationen, die dem 
                                Benutzer mitgeteilt werden, betreffen die angebotenen Produkte oder Dienstleistungen, 
                                die Sicherheit der persönlichen Daten, Aktualisierungen des Netzwerks, Erinnerungsmeldungen, 
                                aber auch vorgeschlagene Angebote des Administrators oder seiner Partner. 
                                Die Kommunikation mit dem Benutzer betrifft auch dessen Betreuung. Die personenbezogenen 
                                Daten werden verwendet, um den Benutzer zu unterstützen, technische Probleme zu lösen und 
                                auf Beschwerden oder Ansprüche des Benutzers zu reagieren.
                            </p>
                            <BoldLi>
                                <p>Dem Benutzer auf elektronischem Wege Angebote zukommen zu lassen.</p>
                            </BoldLi>
                            <p>
                                Die vom Benutzer über das auf der Website verfügbare Kontaktformular zur Verfügung 
                                gestellten personenbezogenen Daten werden vom Administrator im Rahmen seiner 
                                Geschäftstätigkeit zu Marketingzwecken verwendet, insbesondere um dem Benutzer auf 
                                elektronischem Wege kommerzielle Angebote zu unterbreiten.
                            </p>
                            <BoldLi>
                                <p>Dem Benutzer telefonisch kommerzielle Angebote zukommen zu lassen.</p>
                            </BoldLi>
                            <p>
                                Die personenbezogenen Daten des Benutzers, die dieser über das auf der Website verfügbare 
                                Kontaktformular zur Verfügung gestellt hat, werden vom Administrator zum Zweck der 
                                Marketingkommunikation im Rahmen seiner Geschäftstätigkeit verwendet, insbesondere indem
                                er dem Benutzer über Telefonkontakt kommerzielle Angebote unterbreitet.
                            </p>
                            <BoldLi>
                                <p>
                                	Dem Benutzer das Versenden von Kommentaren oder Feedback zu ermöglichen
                                </p>
                            </BoldLi>
                            <p>
                                Der Administrator verwendet die personenbezogenen Daten des Benutzers, um ihm zu 
                                ermöglichen, die Tätigkeit, die Dienstleistungen oder die Produkte des Administrators 
                                oder der mit ihm verbundenen Unternehmen zu kommentieren bzw. eine Meinung dazu 
                                abzugeben.
                            </p>
                        </StyledOl>
                        <p>
                            Der Administrator kann die im Kontaktformular angegebenen personenbezogenen Daten des 
                            Benutzers auch für folgende Zwecke verarbeiten:
                        </p>
                        <StyledOl type="a">
                            <li>
                                <p>
                                    zum Zwecke des Abschlusses und der Erfüllung eines möglichen Vertrags zwischen dem 
                                    Benutzer und dem Administrator und zur Betreuung des Benutzers als Kunde des 
                                    Administrators gemäß Artikel 6 Absatz 1 Buchstabe b der RODO- Verordnung 
                                    (der Datenschutzverordnung);
                                </p>
                            </li>
                            <li>
                                <p>
                                    zur Durchführung von Finanzabrechnungen mit dem Benutzer, der Kunde des Administrators 
                                    ist, zur Erfüllung eines eventuellen zwischen den Parteien geschlossenen Vertrages 
                                    sowie zur eventuellen Geltendmachung von Ansprüchen des Benutzers, der Kunde ist, 
                                    im Rahmen des berechtigten Interesses des Administrators gemäß Artikel 6 Absatz 1 
                                    Buchstabe f) RODO und zur Erfüllung der gesetzlichen Verpflichtungen des Administrators
                                     gegenüber den Steuerbehörden auf der Grundlage gesonderter Regelungen gemäß Artikel 6 
                                    Absatz 1 Buchstabe c) RODO;
                                </p>
                            </li>
                            <li>
                                <p>
                                    zur Durchführung der Marketingtätigkeiten des Administrators im Rahmen des 
                                    berechtigten Interesses des Administrators im Sinne von Artikel 6 Absatz 1 
                                    Buchstabe f des Datenschutzgesetzes und in Übereinstimmung mit den dem Administrator 
                                    gegenüber abgegebenen Absichtserklärungen bezüglich der Marketingkommunikation. 
                                    Zustimmungen, die für Marketingmitteilungen erteilt wurden (z. B. für die Zusendung 
                                    von kommerziellen Informationen per E-Mail oder für die telefonische Kontaktaufnahme 
                                    zu Zwecken des Direktmarketings), können jederzeit widerrufen werden, ohne dass die 
                                    Rechtmäßigkeit der Verarbeitung, die auf der Grundlage der Zustimmung vor deren 
                                    Widerruf erfolgte, berührt wird;
                                </p>
                            </li>
                            <li>
                                <p>
                                    um die in der RODO festgelegten rechtlichen Verpflichtungen des Administrators 
                                    gegenüber dem Benutzer im Sinne von Artikel 6 Absatz 1 Buchstabe c der RODO zu 
                                    erfüllen.
                                </p>
                            </li>
                        </StyledOl>
                        <BoldLi>
                            <p>
                            	WEITERGABE VON PERSONENBEZOGENEN DATEN
                            </p>
                        </BoldLi>
                        <p>
                            Die personenbezogenen Daten werden vom Administrator nicht an Dritte weitergegeben.
                        </p>
                        <BoldLi>
                            <p>
                            	BENUTZERRECHTE
                            </p>
                        </BoldLi>
                        <StyledOl>
                            <BoldLi>
                                <p>
                                    Benutzerrechte
                                </p>
                            </BoldLi>
                            <p>
                                Der Benutzer verfügt in jeder Phase der Verarbeitung seiner Daten über eine Reihe von 
                                Rechten, die es ihm ermöglichen, auf seine Daten zuzugreifen, die Korrektheit der 
                                Datenverarbeitung zu überprüfen, die Daten zu berichtigen, sowie das Recht, der 
                                Datenverarbeitung zu widersprechen, die Löschung der Daten zu verlangen, die 
                                Verarbeitung einzuschränken oder die Daten zu übertragen.
                            </p>
                            <p>
                                Sollte der Benutzer seine Rechte als betroffene Person ausüben wollen, kann er 
                                sich unter den folgenden Kontaktdaten an den Administrator wenden: 
                                <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, Jaworowa 19, 
                                09-400 Maszewo Duże, mboerner@bormat.com.pl.</strong>
                            </p>
                            <BoldLi>
                                <p>
                                	Das Beschwerderecht bei einer Aufsichtsbehörde
                                </p>
                            </BoldLi>
                            <p>
                                Der Benutzer, dessen personenbezogene Daten vom Administrator verarbeitet werden, 
                                hat das Recht, eine Beschwerde bei der für den Schutz personenbezogener Daten 
                                zuständigen Aufsichtsbehörde (dem Vorsitzenden des Amtes für den Schutz personenbezogener 
                                Daten) einzureichen.
                            </p>
                        </StyledOl>
                        <BoldLi>
                            <p>COOKIES</p>
                        </BoldLi>
                        <StyledOl>
                            <li>
                                <p>
                                    Der Administrator informiert, dass während der Nutzung der Website kurze 
                                    Textinformationen, so genannte "Cookies", auf dem Endgerät des Benutzers gespeichert 
                                    werden. Die "Cookies"-Dateien enthalten IT-Daten wie: die IP-Adresse des Benutzers, 
                                    den Namen der Website, von der sie stammen, die Zeit, in der sie auf dem Endgerät 
                                    des Benutzers gespeichert werden, die Aufzeichnung von Parametern und Statistiken 
                                    sowie eine eindeutige Nummer. Die "Cookies"-Dateien werden über den auf dem Endgerät 
                                    des Benutzers installierten Webbrowser an den Server der Website geleitet.
                                </p>
                            </li>
                            <p>
                                Cookies werden auf der Website verwendet, um:
                            </p>
                            <StyledOl type="a">
                                <li>
                                    <p>
                                        die technische Korrektheit und Kontinuität der Sitzung zwischen dem Server der 
                                        Website und dem Endgerät des Benutzers aufrechtzuhalten;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	die Nutzung der Website durch den Benutzer und die Anpassung der Darstellung 
                                        auf den Endgeräten des Benutzers zu optimieren;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	eine sichere Nutzung der Website zu gewährleisten;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	die Statistiken über die Besuche auf den Websites des Services zu sammeln, 
                                        um deren Struktur und Inhalt zu verbessern;
                                    </p>
                                </li>
                            </StyledOl>
                            <p>
                                Im Rahmen des Services werden zwei Arten von "Cookies" verwendet: "Sitzungs- Cookies" 
                                und "permanente Cookies". "Sitzungs"-Cookies" sind Dateien, die automatisch vom Endgerät 
                                des Benutzers gelöscht werden, nachdem sich der Benutzer vom Service abgemeldet oder die 
                                Websites des Services verlassen hat oder nachdem er den Internetbrowser ausgeschaltet hat. 
                                "Permanente" "Cookies" werden auf dem Endgerät des Benutzers für die in den Parametern                              der "Cookies" angegebene Zeit oder bis zu ihrer Löschung durch den Benutzer gespeichert. "Permanente" "Cookies" werden auf dem Endgerät des Benutzers nur mit dessen Zustimmung installiert.
                            </p>
                            <p>Der Administrator informiert hierzu:</p>
                            <StyledOl type="a">
                                <li>
                                    <p>
                                    	die Internet-Browser akzeptieren standardmäßig die Installation von "Cookies" auf 
                                        dem Endgerät des Benutzers. Jeder Benutzer der Website kann jederzeit die 
                                        Einstellungen bezüglich der "Cookies"-Dateien in dem von ihm verwendeten 
                                        Internet-Browser so ändern, dass der Browser die "Cookies"-Dateien automatisch 
                                        blockiert oder den Benutzer jedes Mal darüber informiert, wenn sie in seinem 
                                        Endgerät abgelegt werden. Detaillierte Informationen über die Möglichkeit und die 
                                        Methoden der Handhabung von "Cookies" sind in den Einstellungen des vom Benutzer 
                                        des Services verwendeten Internetbrowsers verfügbar.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	die Einschränkung der Verwendung von Cookies durch einen Benutzer kann sich 
                                        nachteilig auf die Korrektheit und Kontinuität der Bereitstellung von Diensten 
                                        auf der Website auswirken.
                                    </p>
                                </li>
                            </StyledOl>
                            <p>
                                Die im Endgerät des Benutzers installierten Cookies können von Werbeträgern 
                                oder Geschäftspartnern, die mit dem Administrator zusammenarbeiten, verwendet werden.
                                Cookies können nur in Verbindung mit anderen persönlichen Identifikationsdaten, die der 
                                Benutzer dem Administrator bei der Nutzung der Website zur Verfügung stellt, als 
                                personenbezogene Daten angesehen werden. Nur der Administrator hat Zugang zu den Cookies, 
                                die vom Server der Website verarbeitet werden. Wenn ein Benutzer mit dem Speichern und 
                                Empfangen von Informationen in Cookies nicht einverstanden ist, kann er die Regeln für 
                                Cookies über die Einstellungen seines Webbrowsers ändern.
                            </p>
                        </StyledOl>
                        <BoldLi>
                            <p>WEITERE WICHTIGE INFORMATIONEN</p>
                        </BoldLi>
                        <StyledOl>
                            <BoldLi>
                               <p>Schutz der Sicherheit personenbezogener Daten</p>
                            </BoldLi>
                            <p>
                                Der Administrator ergreift geeignete Maßnahmen, um die Sicherheit der personenbezogenen 
                                Daten des Benutzers zu gewährleisten. Die sichere Nutzung der Website wird durch die 
                                Systeme und Verfahren gewährleistet, die zum Schutz vor dem Zugriff und der Weitergabe 
                                von Daten an Unbefugte eingesetzt werden. Darüber hinaus werden die vom Administrator 
                                verwendeten Systeme und Prozesse regelmäßig überwacht, um mögliche Bedrohungen zu erkennen. 
                                Die vom Administrator erhobenen personenbezogenen Daten werden in Computersystemen 
                                gespeichert, zu denen der Zugang streng begrenzt ist.
                            </p>
                            <BoldLi>
                                <p>Speicherung von personenbezogenen Daten</p>
                            </BoldLi>
                            <p>
                                Die Dauer der Speicherung der personenbezogenen Daten der Benutzer richtet sich nach dem 
                                Zweck der Verarbeitung durch den Administrator. Der Administrator speichert 
                                personenbezogene Daten so lange, wie es zur Erreichung der angegebenen Zwecke erforderlich 
                                ist, d.h:
                            </p>
                            <StyledOl type="a">
                                <li>
                                    <p>
                                        für die Dauer der Geschäftstätigkeit des Administrators.
                                    </p>
                                </li>
                            </StyledOl>
                            <p>
                                In jedem der oben genannten Fälle dürfen die Daten nach Ablauf der erforderlichen 
                                Verarbeitungsfrist nur zum Zwecke der Geltendmachung von Ansprüchen vor dem Hintergrund 
                                der Beziehungen zwischen den Parteien bis zur endgültigen Beilegung dieser Ansprüche auf 
                                rechtlichem Wege verarbeitet werden.
                            </p>
                            <BoldLi>
                            	<p>Änderungen der Datenschutzrichtlinie</p>
                            </BoldLi>
                            <p>
                                Um die in dieser Datenschutzrichtlinie enthaltenen Informationen zu aktualisieren und 
                                sie mit dem geltenden Recht in Einklang zu bringen, kann diese Datenschutzrichtlinie 
                                geändert werden. Wenn der Inhalt der Datenschutzrichtlinie geändert wird, wird das am 
                                Ende des Textes angegebene Aktualisierungsdatum geändert. Um sich über den Schutz 
                                personenbezogener Daten zu informieren, empfiehlt der Administrator den Benutzern die 
                                regelmäßige Überprüfung der Bestimmungen der Datenschutzrichtlinie.
                            </p>
                            <BoldLi>
                            	<p>Kontaktdaten</p>
                            </BoldLi>
                            <p>
                                Bei Fragen zu dieser Datenschutzrichtlinie kann sich der Benutzer an den für die 
                                Datenverarbeitung Verantwortlichen wenden: <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ 
                                ODPOWIEDZIALNOŚCIĄ, Jaworowa 19 09-400 Maszewo Duże, </strong>
                                unter Verwendung folgender E-Mail-Adresse: <strong>mboerner@bormat.com.pl.</strong>
                            </p>
                            <p>
                                Darüber hinaus können Sie sich auch per Post an uns wenden: <strong>Jaworowa 
                                19 09-400 Maszewo Duże.</strong>
                            </p>
                        </StyledOl>
                    </StyledOl>
                    <p>
                        Dieses Dokument wurde zuletzt am 07.10.2022 aktualisiert.
                    </p>
                </PageContent>
            </Page>
        </Container>
    )
}

export default PrivacyPolicyDE;