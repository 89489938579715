import "../../../styles/atoms/contactBoxItem/contactBoxItem.css";

function ContactBoxItem ({children, white, hover}){

    return(
        <div className="contact-item-wrapper">
            {children}
            <div className={white ? "contact-item c-white" : "contact-item c-whitesmoke"}>
                <div className="contact-item-stick">
                    <div className={hover ? "contact-item-inner-hasHover" : "contact-item-inner"}/>
                </div>
            </div>
        </div>
    );
}

export default ContactBoxItem;