import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import { SimpleGrid } from "../../../components/templates/simpleGrid/simpleGrid.styled";
import OurOfferItem from "../../../components/organisms/ourOffer/ourOfferItem"
import { Link } from "react-router-dom";
import "aos/dist/aos.css"
import styled from "styled-components";
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

const SmallParagraph = styled.p`
    font-size: 15px;
`

function OurOfferDE(){
    return(
        <Page isPadding={true} bgcolor="whitesmoke">
            <PageContent>
                <PageHeader border>
                    Unser Angebot
                </PageHeader>
                <br />
                <br />
                <SimpleGrid width="260px" gap="30px" columns="auto auto auto auto">
                    <Link data-aos-once data-aos="fade-up-right" data-aos-duration={1000} className="link" to={"/de/horyzontalne-przewierty-sterowane"}>
                        <OurOfferItem label={"Więcej"} icon={"fa6-solid:bore-hole"} rotate={1} vFlip={true}>
                            <h2>Gesteuerte Horizontal-Spülbohrungen<br/><SmallParagraph>(z. B. im schwierigen Boden und Fels)</SmallParagraph></h2>
                        </OurOfferItem>
                    </Link>
                    <Link data-aos-once data-aos="fade-up" data-aos-duration={1000} className="link" to={'/de/przeciski-podziemne'}>
                        <OurOfferItem label={"Więcej"} icon={"fluent:pipeline-32-filled"}>
                            <h2>Unterirdischer Vortrieb</h2>
                        </OurOfferItem>
                    </Link>
                    <Link data-aos-once data-aos="fade-up" data-aos-duration={1000} className="link" to={'/de/prace-ziemne'}>
                        <OurOfferItem label={"Więcej"} icon={"fa6-solid:person-digging"}>
                            <h2>Tiefbau</h2>
                        </OurOfferItem>
                    </Link>
                    <Link data-aos-once data-aos="fade-up-left" data-aos-duration={1000} className="link" to={'/de/zgrzewanie-rur'}>
                        <OurOfferItem label={"Więcej"} icon={"fa6-solid:fire-flame-curved"}>
                            <h2>Rohrschweißen </h2>
                        </OurOfferItem>
                    </Link>
                </SimpleGrid>
            </PageContent>
        </Page>
    );
}

export default OurOfferDE;