import "../../../styles/atoms/blueRedButton/blueRedButton.css";

function BlueRedButton ({children}){
    return(
        <button className="blue-red-button">
            {children}
        </button>
    );
}

export default BlueRedButton;