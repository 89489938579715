import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTileStyled } from "./pageTiles.styled";

export default function PageTile({isDesktop, icon, children, width, height, maxWidth, minHeight}){
    return(
        <PageTileStyled isDesktop={isDesktop} width={width} height={height} maxWidth={maxWidth} minHeight={minHeight}>
            <FontAwesomeIcon className="icon" icon={icon}/>
            {children}
        </PageTileStyled>
    );
}