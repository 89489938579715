import styled from "styled-components";

const StyledSnake = styled.div`
    width: 80%;
    position: relative;
    svg{
        position: absolute;
        top: -50px;
        left: 26px;
    }
    transform: ${(props) => props.upsideDown ? "rotate(180deg) scaleX(-1);": "none"};
`

function SnakePath({upsideDown}){
    return(
       <StyledSnake upsideDown={upsideDown}>
         <svg height="120" width="720">
            <g fill="none" stroke="var(--red)" strokeWidth={1}>
                <path strokeDasharray="5,5" d="M 25 0 Q 100 100 350 50 Q 550 0 650 100 "/>
            </g>
        </svg>
       </StyledSnake>
    );
}

export default SnakePath;