import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { PageText } from "../../../components/templates/pageText/pageText";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { Header } from "../../../components/templates/header/header";
import { PageTilesStyled } from "../../../components/templates/pageTiles/pageTiles.styled";
import PageTile from "../../../components/templates/pageTiles/pageTile";
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from "../../../functions/windowsDiemsions";

function PPDE(){
    const tileHeight = "100%";
    const tileWidth = "100%";
    const maxWidth = "300px";
    const minHeight = "300px";
    const { width, height } = useWindowDimensions();
    const isDesktop = width > 991.98;

    return(
        <div>
            <MainHeader bgcolor="whitesmoke">UNTERIRDISCHER VORTRIEB</MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <Paragraph>
                        GRABENLOSER KANALBAU MITTELS SCHUTZROHREN (AUS STAHL ODER STEINZEUG)
                    </Paragraph>
                    <br />
                    <Paragraph bold={true}>
                        Der Hydraulik-Vortrieb ist im Gegensatz zum offenen Aushub ein grabenloses Verfahren, 
                        bei dem die Rohrverlegung unterirdisch erfolgt, ohne dass ein Baugraben ausgehoben werden muss.  
                    </Paragraph>
                    <br />
                    <br />
                    <PageText>
                        <Header>ANWENDUNGSBEREICH DES GRABENLOSEN VERFAHRENS</Header>
                        <Paragraph>
                            <ul>
                                <li>Bereitstellung von Versorgungseinrichtungen für neue Baustellen</li>
                                <li>Herstellen von Anschlüssen an Gebäuden </li>
                                <li>Überquerungen unter Straßen, Autobahnen, Eisenbahnlinien, Wasserläufen</li>
                                <li>Überquerungen unter Denkmälern, Gebäuden</li>
                                <li>Überquerungen unter unterirdischen Wassergewinnungsgebieten</li>
                            </ul>
                        </Paragraph>
                    </PageText>
                    <PageText>
                    <Header>VORTEILE EINES HYDRAULIK-VORTRIEBS</Header>
                        <PageTilesStyled>
                            <PageTile isDesktop={isDesktop} icon={faStopwatch} width={tileWidth} height={tileHeight} maxWidth={maxWidth} minHeight={minHeight}>
                                <Paragraph bold={true} border={true}>KURZE BAUZEIT</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>sicher für unterirdische Infrastrukturen</li>
                                        <li>
                                            Eigentümerfreundliche Methode: keine Beeinträchtigung des täglichen Lebens 
                                            am Einsatzort
                                        </li>
                                    </ul>
                                </Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} icon={faClockRotateLeft} width={tileWidth} height={tileHeight} maxWidth={maxWidth} minHeight={minHeight}>
                                <Paragraph bold={true} border={true}>VERLÄNGERTE BETRIEBSDAUER</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>Besonders hohe Bauqualität und Sicherheit des Kanals</li>
                                        <li>Sicherheit der Baukonstruktion</li>
                                        <li>Eliminierung der Kanalabsenkung</li>
                                    </ul>
                                </Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} icon={faShieldHalved} width={tileWidth} height={tileHeight} maxWidth={maxWidth} minHeight={minHeight}>
                                <Paragraph bold={true} border={true}>SCHUTZ DES STRAßENBELAGS</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>Minimaler Eingriff in den Straßenraum</li>
                                        <li>umweltsicher, Schutz von Fauna und Flora</li>
                                    </ul>
                                </Paragraph>
                            </PageTile>
                        </PageTilesStyled>
                    </PageText>
                </PageContent>
            </Page>
        </div>
    );
}

export default PPDE;