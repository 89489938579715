import "../../../styles/pages/home/jobOfferHome/jobOfferHome.css"
import WhiteBlueButton from "../../../components/atoms/white-blue-button/white-blue-button";
import "aos/dist/aos.css"
import { Link } from "react-router-dom";
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

function JobOfferHomeDE(){
    return(
        <div className="jobOfferHome">
            <div className="jobOfferHome-content">
                <PageHeader>
                    SUCHEN SIE EINEN JOB? WERDEN SIE TEIL UNSERES TEAMS!
                </PageHeader>
                <Link to={"/de/job-offer"} data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <WhiteBlueButton>
                        MEHR ERFAHREN
                    </WhiteBlueButton>
                </Link>
            </div>
        </div>
    );
}

export default JobOfferHomeDE;