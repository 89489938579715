import React, { useState } from "react";
import { AccordionStyled, Panel } from "./accordion.styled";
export default function AccordionPL(){
    const [open, setOpen] = useState(0);
    return(
        <div>
            <div onClick={(() => {open===1 ? setOpen(0) : setOpen(1)})}>
                <AccordionStyled isOpen={open === 1 ? true : false}>
                    <p>1.	Przewiert pilotażowy</p>
                </AccordionStyled>
                <Panel isOpen={open === 1 ? true : false}>
                    <p>
                        Polega na umieszczeniu głowicy wiercącej z płetwą sterującą i sondą pomiarową, 
                        skierowaną pod odpowiednim kątem natarcia, w otworze pilotażowym, która wwierca się w grunt 
                        doczepiając kolejno żerdzie wiertnicze. Za pośrednictwem lokalizatora elektronicznego 
                        ( umieszczonego w korpusie głowicy wiercącej ) wytycza się żądaną trajektorię przewiertu.
                    </p>
                </Panel>
            </div>
            <div onClick={(() => {open===2 ? setOpen(0) : setOpen(2)})}>
                <AccordionStyled isOpen={open === 2 ? true : false}>
                    <p>
                        2.	Rozwiercanie
                    </p>
                </AccordionStyled>
                <Panel isOpen={open === 2 ? true : false}>
                    <p>
                        Po wykonaniu precyzyjnego przewiertu pilotażowego w miejsce głowicy sterującej montuje 
                        się dobrany odpowiednio do parametrów technicznych i rodzaju gruntu rozwiertak, który 
                        powracając wykonuje ruch obrotowy, tym samym zwiększając średnicę otworu. W czasie wykonywania 
                        całości zadania a szczególnie tego etapu, podawana jest odpowiednio spreparowana , 
                        całkowicie biodegradowalna płuczka wiertnicza, która służy do wyprowadzania urobku i 
                        ciągłego stabilizowania wykonanego otworu. W przypadku większych średnic rozwiercanie 
                        otworu odbywa się stopniowo z zastosowaniem rozwiertaków o coraz większej średnicy.
                    </p>
                </Panel>
            </div>
            <div onClick={(() => {open===3 ? setOpen(0) : setOpen(3)})}>
                <AccordionStyled isOpen={open === 3 ? true : false}>
                    <p>3.	Przeciąganie rurociągu </p>
                </AccordionStyled>
                <Panel isOpen={open === 3 ? true : false}>
                    <p>
                        Do otworu poszerzonego na żądaną średnicę wprowadza się uprzednio przygotowany rurociąg, 
                        umieszczony tuż za ostatnim rozwiertakiem za pośrednictwem specjalnej głowicy wciągającej. 
                        W przypadku niewielkich średnic istnieje możliwość równoczesnego rozwiercenia otworu i 
                        wciągania rury podczas drugiego etapu wykonywanego zadania.
                    </p>
                </Panel>
            </div>
        </div>
    );
}