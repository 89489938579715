import styled from "styled-components";
import { Page } from "../../components/templates/page/page";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import ContactBoxItem from "../../components/atoms/contactBoxItem/contactBoxItem";
import React from 'react';
import Lottie from 'react-lottie';
import animationData from "../../lotties/hourglass.json"
import BlueRedButton from "../../components/atoms/blueRedButton/blueRedButton";
import CustomLink from "../../components/atoms/customLink/customLink";

const StyledPage = styled.div`
    margin: -20px 0;
    & > *{
        margin: 20px 0;
    }
    z-index: 10000;
    background: var(--whitesmoke);
    width: 100%;
    height: calc(100vh + 20px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledText = styled.div`
    color: var(--red);
    text-align: center;
    h1{
        margin: 0;
    }

    p{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
    }
`

const StyledIcon = styled.div`
    padding: 8px 10px;
    position: absolute;
    top: 53px;
    left: 82px;
    z-index: 3;
    color: var(--red);
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledBox = styled.div`
    margin: -50px 0 -50px 0px;
    & > *{
        margin: 50px 0;
    }
    display:flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
`

const StyledButtons = styled.div`
    display: flex;
    margin: 0 -10px;
    & > *{
        margin: 0 10px;
    }
`
const StyledButton = styled.button`
    outline: none;
    text-align: center;
    border: 2px solid var(--blue);
    background-color: var(--blue);
    color: var(--white);
    transition: 0.5s ease;
    cursor: pointer;
    padding: 10px 40px;
    z-index: 1;
    font-family: 'white Hat Display', sans-serif;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.15vw;
    text-transform: uppercase;
    align-self: flex-start;

    &:hover{
        background-color: var(--red);
        border: 2px solid var(--red);
        color: var(--white);
        transition: 0.5s ease;
    }
`



function WorkingPage(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return(
        <div>
            <Page bgcolor="whitesmoke">
                <PageContent>
                    <StyledPage>
                        <ContactBoxItem>
                            <StyledIcon>
                                <Lottie 
                                    options={defaultOptions}
                                    height={250}
                                    width={800}
                                    isClickToPauseDisabled={true}
                                />
                            </StyledIcon>
                        </ContactBoxItem>
                            <StyledBox>
                                <StyledText>
                                    <h1>
                                        Coming soon...
                                    </h1>
                                </StyledText>
                                {/* <StyledText>
                                    <h2>
                                        Set current language:
                                    </h2>
                                </StyledText> */}
                                <StyledButtons>
                                    <CustomLink refresh to={"/pl"}>
                                        <StyledButton>PL</StyledButton>
                                    </CustomLink>
                                    <CustomLink refresh to={"/de"}>
                                        <StyledButton>DE</StyledButton>
                                    </CustomLink>
                                </StyledButtons>
                            </StyledBox>
                    </StyledPage>
                </PageContent>
            </Page>
        </div>
    );
}
export default WorkingPage;