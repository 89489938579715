import styled from 'styled-components'

export const PageTilesStyled = styled.div`
    display: flex;
    padding: 40px 0px;
    flex-wrap: wrap;
      
    @media (max-width: 767.98px) {
        margin: -20px;
        & > *{
            margin: 20px;
        }
        justify-content: left;
    }
        
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        margin: -45px;
        & > *{
            margin: 45px;
        }
        justify-content: left;
    }
        
    @media (min-width: 991.99px) and (max-width: 1199.98px) {
        margin: -45px;
        & > *{
            margin: 45px;
        }
        justify-content: left;
    }
        
    @media (min-width: 1199.99px) {
        justify-content: space-between;
    }
`

export const PageTileStyled = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 30px;
    -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    transition: 0.3s ease;
    max-width: ${(props) => props.maxWidth};
    min-height: ${(props) => props.minHeight};

    &:hover{
        ${(props) => props.isDesktop && "transform: translateY(-15px); transition: 0.3s ease;"}
    }

    .icon{
        font-size: 40px;
        padding: 0px 2px 20px 2px;
        color: var(--blue);
        margin-right: auto;
    }

    div{
        margin-top: 10px;
        letter-spacing: 2px;
        padding: 0;
    }

    ul{
        padding-left: 17px;
        margin: 0;
    }
`