import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { Page } from "../../components/templates/page/page";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import { Paragraph } from "../../components/templates/paragraph/paragraph";
import { PageText } from "../../components/templates/pageText/pageText";
import LoadingScreen from "../../components/organisms/loadingScreen/loadingScreen";
import { useState } from "react";
import ContentAndImage from "../../components/templates/contentAndImage/contentAndImage";
import { PageContainer } from "../../components/templates/pageContainer/pageContainer";

function RealizationsEN(){
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const handleImageLoaded = () => {
        setIsImageLoaded(true);
    }
    
    return(
        <PageContainer bgcolor="whitesmoke">
            <LoadingScreen isLoaded={isImageLoaded}/>
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                REALIZATIONS
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <ContentAndImage handleImageLoaded={handleImageLoaded} src={"/images/realizations/realizations.jpg"} blue right>
                        <Paragraph>
                            Dotychczasowe realizacje to wykonanie ponad <strong>2000 km </strong>przewiertów 
                            o różnych średnicach i gruntach.
                        </Paragraph>
                        <PageText>
                            <Paragraph>
                                Z najlepszymi firmami takimi jak
                                <ul>
                                    <li>CLEMMENS REUSCHENBACH GmbH</li>
                                    <li>Aytac Bau GmbH</li>
                                    <li>Knaak Rohrvortrieb GmbH</li>
                                    <li>Rober Bau GmbH</li>
                                    <li>Baununternehmen Gunter Pompe GmbH</li>
                                    <li>Schoner Tel Gmbh</li>
                                    <li>Bergert Hoch GmbH</li>
                                    <li>i inni</li>
                                </ul>
                                budowaliśmy sieci wodociągowe, kanalizacyjne, telekomunikacyjne i energetyczne.
                            </Paragraph>
                        </PageText>
                    </ContentAndImage>
                </PageContent>
            </Page>
        </PageContainer>
    );
}
export default RealizationsEN;