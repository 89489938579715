import styled from 'styled-components'

export const AccordionStyled = styled.div`
    border: 1px solid var(--blue);
    background-color: var(--${(props) => props.isOpen ? "blue" : "white"});
    color: var(--${(props) => props.isOpen ? "white" : "blue"});;
    cursor: pointer;
    padding: 18px;
    text-align: left;
    margin-top: 20px;
    outline: none;
    font-size: 15px;
    transition: 0.3s ease;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;

    &:hover{
        background-color: var(--blue); 
        color: var(--white);
        transition: 0.3s ease;
    }
    
    p{
        font-weight: 500;
        margin: 0;
    }
`

export const Panel = styled.div`
    padding: 0px 18px;
    background-color: white;
    overflow: hidden;
   ${(props) => props.isOpen ? "height: auto":""};
    max-height: ${(props) => props.isOpen ? "9999px" : "0"};
    transition: all 0.4s cubic-bezier(${(props) => props.isOpen ? "1,0,1,0" : "0,1,0,1"});

`