import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { Page } from "../../components/templates/page/page";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import { Paragraph } from "../../components/templates/paragraph/paragraph";
import { PageText } from "../../components/templates/pageText/pageText";
import LoadingScreen from "../../components/organisms/loadingScreen/loadingScreen";
import { useState } from "react";
import ContentAndImage from "../../components/templates/contentAndImage/contentAndImage";
import { PageContainer } from "../../components/templates/pageContainer/pageContainer";

function RealizationsDE(){
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const handleImageLoaded = () => {
        setIsImageLoaded(true);
    }
    
    return(
        <PageContainer bgcolor="whitesmoke">
            <LoadingScreen isLoaded={isImageLoaded}/>
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                Unsere Leistungen
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <ContentAndImage handleImageLoaded={handleImageLoaded} src={"/images/realizations/realizations.jpg"} blue right>
                        <Paragraph>
                            Zu den bisher abgeschlossenen Projekten gehören mehr als <strong>2.000 km</strong> Bohrungen ausgeführt in verschiedenen Rohrdurchmessern und Bodenarten.
                        </Paragraph>
                        <PageText>
                            <Paragraph>
                                Mit renommierten Unternehmen wie der
                                <ul>
                                    <li>CLEMMENS REUSCHENBACH GmbH</li>
                                    <li>Aytac Bau GmbH</li>
                                    <li>Knaak Rohrvortrieb GmbH</li>
                                    <li>Rober Bau GmbH</li>
                                    <li>Bauunternehmen Günter Pompe GmbH</li>
                                    <li>SchönerTel GmbH</li>
                                    <li>Bergert Hoch GmbH</li>
                                    <li>und anderen</li>
                                </ul>
                                haben wir bereits Wasser-, Kanalisations-, Telekommunikations- und Energieversorgungsnetze gebaut.
                            </Paragraph>
                        </PageText>
                    </ContentAndImage>
                </PageContent>
            </Page>
        </PageContainer>
    );
}
export default RealizationsDE;