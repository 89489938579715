import styled from 'styled-components'

export const HeaderContainer = styled.div`
    padding: calc(${(props) => props.isMobile ? "10px" : "40px"} + 76px) 0px 15px 0px;
    background-color: var(--${(props) => props.bgcolor});
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const StyledMainHeader = styled.h1`
    border-bottom: ${(props) => props.border ? "1px" : "0"} solid var(--lightgrey);
    text-align: left;
    width: 100%;
    text-transform: uppercase;
`

export const HeaderWrapper = styled.div`
  @media(max-width: 599.99px){ 
    min-width: 90%;
    padding: 0% 5%;
  }

  @media (min-width: 599.99px) and (max-width: 767.98px) {
    width: 540px;
    padding: 0% 5%;
  }

  @media (min-width: 767.99px) and (max-width: 991.98px) {
    width: 720px;
    padding: 0% 5%;
  }
    
  @media (min-width: 991.99px) and (max-width: 1199.98px) {
    width: 960px;
  }
    
  @media (min-width: 1199.99px) {
    width: 1140px;
  }
`