import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import DropDownOption from "../../atoms/dropDownOption/dropDownOption";

const Container = styled.div`
    @media (max-width: 991.98px) {
        width: 100%;
        flex: auto;
        padding: 0 15px;
        li{
            border-right: 1px solid var(--lightgrey);
            padding: 0 10px;
        }
        li:hover{
            color: var(--red);
        }
    }
    
    @media (min-width: 991.99px){
        width: fit-content;
        height: 100%;
        ul,li{
            height: 100%;
        }
        padding-left: 15px;
    }

    display: flex;
    justify-content: flex-end;
    ul{
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;
        align-items: center;
    }
    li{
        font-size: 13px;
        color: var(--black);
        font-weight: 700;
        letter-spacing: 0.15vw;
        transition: 0.3s ease;
        cursor: pointer;
    }
      
    li:hover{
        transition: 0.3s ease;
    }
    a{
        color: inherit;
    }

    .selected{
        color: var(--red);
    }
`

function NavbarLanguages({language, changeLanguage}){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMobile = windowWidth <= 991.98;
    const languages = [
        {link: '/pl', label: 'PL', name: 'pl'},
        {link: '/de', label: 'DE', name: 'de'},
        {link: '/en', label: 'EN', name: 'en'},
    ]
    const getLang = languages.find((lang) => lang.name === language);
    const [currentLanguage, setCurrentLanguage] = useState(getLang);
    const handleLanguageChange = (lang) =>{
        changeLanguage(lang.name);
        setCurrentLanguage(lang);
    }
    
    return(
        <Container>
            <ul>
                {isMobile ? 
                    <ul>
                        {languages.map((language) => (
                            <li onClick={() => handleLanguageChange(language)} key={language.name}>
                                <div className={language.label === currentLanguage.label ? "selected" : ""}>
                                    {language.label}
                                </div>
                            </li>
                        ))}
                    </ul>
                    :
                    <li>
                        <DropDownOption label={currentLanguage.label}>
                            <ul>
                                {languages.map((language) => (
                                    <li onClick={() => handleLanguageChange(language)} key={language.name}>
                                        <div className="option">
                                            {language.label}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </DropDownOption>
                    </li>
                }
            </ul>      
        </Container>
    );
}

export default NavbarLanguages;