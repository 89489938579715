import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import "aos/dist/aos.css"
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag"
import Flag from "../../../components/atoms/flag/flag";

const FlagsContainer = styled.div`
    border-bottom: 1px solid var(--lightgrey);
    border-top: 1px solid var(--lightgrey);
    min-width: 100%;
`

const FlagGrid = styled.div`
    display: flex;
    justify-content: space-between;
    margin: -10px;
    & > *{
        margin: 10px;
    }
    width: 100%;
    @media (max-width: 991.98px){
        flex-wrap: wrap;
    }
`
const FlagsPage = styled.div`
    background-color: var(--whitesmoke);
    padding: 15vmin 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
`

function FlagsPL(){
    return(
        <FlagsPage>
            <PageContent>
                <FlagsContainer>
                    <FlagGrid>
                        <Flag text="Unia Europejska">
                            <ReactCountryFlag
                                countryCode="EU"
                                svg
                                style={{
                                    width: '80px',
                                    height: '80px',
                                }}
                                title="EU"
                            />
                        </Flag>

                        <Flag text="Rzeczpospolita Polska">
                            <ReactCountryFlag
                                countryCode="PL"
                                svg
                                style={{
                                    width: '80px',
                                    height: '80px',
                                }}
                                title="PL"
                            />
                        </Flag>
                        
                        <Flag text="Republika Federalna Niemiec">
                            <ReactCountryFlag
                                countryCode="DE"
                                svg
                                style={{
                                    width: '80px',
                                    height: '80px',
                                }}
                                title="DE"
                            />
                        </Flag>
                    </FlagGrid>
                </FlagsContainer>
            </PageContent>
        </FlagsPage>
    );
}

export default FlagsPL;