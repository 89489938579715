import React, { useState } from "react";
import { AccordionStyled, Panel } from "./accordion.styled";
export default function AccordionDE(){
    const [open, setOpen] = useState(0);
    return(
        <div>
            <div onClick={(() => {open===1 ? setOpen(0) : setOpen(1)})}>
                <AccordionStyled isOpen={open === 1 ? true : false}>
                    <p>1.	Pilotbohrung</p>
                </AccordionStyled>
                <Panel isOpen={open === 1 ? true : false}>
                    <p>
                        Bei der Pilotbohrung wird ein Bohrkopf mit einer Steuerplatte und einer Messsonde, 
                        die in einem geeigneten Anstellwinkel orientiert sind, in das Pilotbohrloch gesetzt, 
                        welcher durch sukzessives Ansetzen von Bohrstangen in den Boden gebohrt wird. 
                        Die gewünschte Bohrtrajektorie wird mit Hilfe eines elektronischen Ortungsgeräts 
                        (das sich im Bohrkopf befindet) aufgezeichnet.
                    </p>
                </Panel>
            </div>
            <div onClick={(() => {open===2 ? setOpen(0) : setOpen(2)})}>
                <AccordionStyled isOpen={open === 2 ? true : false}>
                    <p>
                        2.	Aufweiten des Bohrlochs
                    </p>
                </AccordionStyled>
                <Panel isOpen={open === 2 ? true : false}>
                    <p>
                        Nach der präzisen Pilotbohrung wird anstelle des Steuerkopfes ein nach den technischen 
                        Parametern und der Bodenart ausgewählter Räumer montiert, der auf seinem Rückweg eine 
                        Drehbewegung ausführt und so den Durchmesser des Bohrlochs vergrößert. Während der gesamten 
                        Maßnahme und insbesondere in dieser Phase wird eine entsprechend aufbereitete, vollständig 
                        biologisch abbaubare Bohrspülung zugeführt, die dazu dient, das Aushubmaterial aus dem Bohrloch 
                        herauszuführen und es kontinuierlich zu stabilisieren. Bei größeren Durchmessern wird das 
                        Bohrloch schrittweise mit immer größeren Räumerdurchmessern aufgeweitet.
                    </p>
                </Panel>
            </div>
            <div onClick={(() => {open===3 ? setOpen(0) : setOpen(3)})}>
                <AccordionStyled isOpen={open === 3 ? true : false}>
                    <p>3.	Einziehen von Rohren</p>
                </AccordionStyled>
                <Panel isOpen={open === 3 ? true : false}>
                    <p>
                        In das auf den gewünschten Durchmesser aufgeweitete Bohrloch wird ein zuvor vorbereitetes 
                        Rohr eingeführt, das direkt hinter dem letzten Räumer mit einem speziellen Ziehkopf platziert 
                        wird. Bei kleinen Durchmessern ist es möglich, in der zweiten Phase der Arbeit gleichzeitig 
                        das Bohrloch aufzuweiten und das Rohr einzuziehen.
                    </p>
                </Panel>
            </div>
        </div>
    );
}