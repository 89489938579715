import "../../../styles/pages/home/main/main.css"
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import { StyledMainHeader }from "../../../components/templates/mainHeader/mainHeader.styled"
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import "aos/dist/aos.css"
import { useEffect } from "react";
import styled from "styled-components";

const PhotoSource = styled.div`
    position: absolute;
    z-index: 10000;
    right: 20px;
    bottom: 20px;
    color: var(--white);
    font-size: 10px;
    opacity: 0.7;
    a{
        color: var(--white);
    }
`

function HomeMainPL({handlePageLoad}){
    useEffect(() => {
        handlePageLoad();
    })
    
     return(
        <div className="homeMain">
            <PhotoSource>
                photo: {" "}
                <a href="https://www.ditchwitch.com/">ditchwitch.com</a>
            </PhotoSource>
            <PageContent>
                <div className="mainText">
                    <header>
                        <StyledMainHeader data-aos-delay={1000} data-aos-duration={1000} data-aos="fade-right">
                            HORYZONTALNE PRZEWIERTY STEROWANE
                        </StyledMainHeader>
                    </header>
                    <div className="paragraph">
                        <Paragraph data-aos-delay={1000} data-aos-duration={1000} data-aos="fade-left">
                            Naszym Klientom oferujemy wykonywanie instalacji podziemnych przy wykorzystaniu 
                            najnowocześniejszych technologii bezwykopowych takich jak przewierty i przeciski. 
                        </Paragraph>
                        <Paragraph data-aos-delay={1000} data-aos-duration={1000}  data-aos="fade-left">
                            Ich zastosowanie pozwala na uniknięcie ograniczenia ruchu pojazdów podczas przekraczania 
                            szlaków komunikacyjnych, naruszania brzegów rzek, jezior, wałów przeciwpowodziowych przy 
                            zredukowanej do minimum ingerencji w środowisko naturalne.
                        </Paragraph>
                    </div>
                </div>
            </PageContent>
        </div>
    );
}

export default HomeMainPL;