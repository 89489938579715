import './App.css';
import HomePL from "../src/pages/home/homePL"
import HomeDE from "../src/pages/home/homeDE"
import HomeEN from "../src/pages/home/homeEN"
import HPSPL from './pages/offer/horyzontalne-przewierty-sterowane/hpsPL';
import HPSDE from './pages/offer/horyzontalne-przewierty-sterowane/hpsDE';
import HPSEN from './pages/offer/horyzontalne-przewierty-sterowane/hpsEN';
import PPPL from './pages/offer/przeciski-podziemne/ppPL';
import PPDE from './pages/offer/przeciski-podziemne/ppDE';
import PPEN from './pages/offer/przeciski-podziemne/ppEN';
import PZPL from './pages/offer/prace-ziemne/pzPL';
import PZDE from './pages/offer/prace-ziemne/pzDE';
import PZEN from './pages/offer/prace-ziemne/pzEN';
import ZRPL from './pages/offer/zgrzewanie-rur/zrPL';
import ZRDE from './pages/offer/zgrzewanie-rur/zrDE';
import ZREN from './pages/offer/zgrzewanie-rur/zrEN';
import GalleryPL from './pages/gallery/galleryPL';
import GalleryDE from './pages/gallery/galleryDE';
import GalleryEN from './pages/gallery/galleryEN';
import JobOfferPL from './pages/jobOffer/jobOfferPL';
import JobOfferDE from './pages/jobOffer/jobOfferDE';
import JobOfferEN from './pages/jobOffer/jobOfferEN';
import RealizationsPL from './pages/realizations/realizationsPL';
import RealizationsDE from './pages/realizations/realizationsDE';
import RealizationsEN from './pages/realizations/realizationsEN';
import ContactPL from './pages/contact/contactPL';
import ContactDE from './pages/contact/contactDE';
import ContactEN from './pages/contact/contactEN';
import PageNotFound from './pages/pageNotFound/index'
import Navbar from './components/organisms/navbar/navbar';
import Footer from './components/organisms/footer/footer';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import BackToTopButton from './components/atoms/backToTopButton/backToTopButton';
import ScrollToTop from './functions/scrollToTop';
import "aos/dist/aos.css"
import { useEffect } from 'react';
import AOS from "aos";
import WorkingPage from './pages/workingPage/workingPage';
import { useState } from 'react';
import {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "./functions/ga-utils";
import ReactGA from 'react-ga';
import styled from 'styled-components';
import PrivacyPolicyPL from './pages/privacyPolicy/privacyPolicyPL';
import PrivacyPolicyDE from './pages/privacyPolicy/privacyPolicyDE';
import CookiePopUp from './components/organisms/cookiePopUp/cookiePopUp';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const Container = styled.div`
min-height: calc(100vh - ${(props) => props.footerHeight}px);
`

function App() {

  useEffect(()=>{
    AOS.init({duration: 1000});
  },[]);

  document.querySelectorAll('img')
    .forEach((img) =>
      img.addEventListener('load', () =>
        AOS.refresh()
      )
    );

  const [isNavbarLoaded, setIsNavbarLoaded] = useState(false);
  const handleNavbarLoad = () =>{
    setIsNavbarLoaded(true);
  }

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };
  
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  var userLang = navigator.language.slice(0,2) || navigator.userLanguage.slice(0,2); 
  const getLang = window.location.pathname.slice(1,3);
  const isLangCorrect = getLang === 'pl' || getLang === 'de' || getLang === 'en';
  const isUserLangCorrect = userLang === 'pl' || userLang ==='de' || userLang === 'en';
  const [language, setLanguage] = useState(isLangCorrect ? getLang : isUserLangCorrect ? userLang : 'en');
  const [footerHeight, setFooterHeight] = useState(0);
  return (
    <Router>
      <ScrollToTop/>
      <div className="App">
      <CookiePopUp handleAcceptCookie={handleAcceptCookie} handleDeclineCookie={handleDeclineCookie} language={language}/>
        <Navbar language={language} setLanguage={setLanguage} handleNavbarLoad={handleNavbarLoad}/>
        <Container footerHeight={footerHeight}>
          <Routes>
            <Route path='/' element={<Navigate to={"/"+language} />}/>
            <Route exact path='/pl' element={
              <HomePL isNavbarLoaded={isNavbarLoaded}/>
            }/>
            <Route exact path='/de' element={
              <HomeDE isNavbarLoaded={isNavbarLoaded}/>
            }/>
            {/* <Route exact path='/en' element={
              <HomeEN isNavbarLoaded={isNavbarLoaded}/>
            }/> */}
            <Route path='/en/*' element={<WorkingPage/>}/>
            <Route exact path='/en/404' element={<WorkingPage/>}/>
            <Route exact path='/pl/horyzontalne-przewierty-sterowane' element={<HPSPL/>}/>
            <Route exact path='/de/horyzontalne-przewierty-sterowane' element={<HPSDE/>}/>
            {/* <Route exact path='/en/horyzontalne-przewierty-sterowane' element={<HPSEN/>}/> */}
            <Route exact path='/pl/przeciski-podziemne' element={<PPPL/>}/>
            <Route exact path='/de/przeciski-podziemne' element={<PPDE/>}/>
            {/* <Route exact path='/en/przeciski-podziemne' element={<PPEN/>}/> */}
            <Route exact path='/pl/prace-ziemne' element={<PZPL/>}/>
            <Route exact path='/de/prace-ziemne' element={<PZDE/>}/>
            {/* <Route exact path='/en/prace-ziemne' element={<PZEN/>}/> */}
            <Route exact path='/pl/zgrzewanie-rur' element={<ZRPL/>}/>
            <Route exact path='/de/zgrzewanie-rur' element={<ZRDE/>}/>
            {/* <Route exact path='/en/zgrzewanie-rur' element={<ZREN/>}/> */}
            <Route exact path='/pl/gallery' element={<GalleryPL/>}/>
            <Route exact path='/de/gallery' element={<GalleryDE/>}/>
            {/* <Route exact path='/en/gallery' element={<GalleryEN/>}/> */}
            <Route exact path='/pl/job-offer' element={<JobOfferPL/>}/>
            <Route exact path='/de/job-offer' element={<JobOfferDE/>}/>
            {/* <Route exact path='/en/job-offer' element={<JobOfferEN/>}/> */}
            <Route exact path='/pl/realizations' element={<RealizationsPL/>}/>
            <Route exact path='/de/realizations' element={<RealizationsDE/>}/>
            {/* <Route exact path='/en/realizations' element={<RealizationsEN/>}/> */}
            <Route exact path='/pl/contact' element={<ContactPL/>}/>
            <Route exact path='/de/contact' element={<ContactDE/>}/>
            {/* <Route exact path='/en/contact' element={<ContactEN/>}/> */}
            <Route exact path='/pl/private-policy' element={<PrivacyPolicyPL/>}/>
            <Route exact path='/de/private-policy' element={<PrivacyPolicyDE/>}/>
            <Route path={"/"+language+"/404"} element={<PageNotFound language={language}/>}/>
            <Route path='/*' element={<Navigate to={"/"+language+"/404"} />}/>
          </Routes>
        </Container>
        <Footer language={language} setFooterHeight={setFooterHeight}/>
        <BackToTopButton/>
      </div>
    </Router>
  );
}

export default App;
