import "../../../styles/organisms/footer/footerItem.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function FooterItem({icon, children}){
    return(
        <div className="footerItem">
            <div className="icon">
                <FontAwesomeIcon icon={icon}/>
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default FooterItem;