import styled from "styled-components";
import { Page } from "../../components/templates/page/page";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import ContactBoxItem from "../../components/atoms/contactBoxItem/contactBoxItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlueRedButton from "../../components/atoms/blueRedButton/blueRedButton";
import { Link } from 'react-router-dom';

const StyledPage = styled.div`
    @media (max-width: 599.98px) {
        flex-direction: column;
        margin: -20px 0;
        & > *{
            margin: 20px 0;
        }
    }
    padding: 105px 0 30px 0;
    height: calc(100vh - 135px);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledText = styled.div`
    color: var(--red);
    text-align: center;
    h1{
        margin: 0;
    }
`

const StyledIcon = styled.div`
    font-size: 70px;
    padding: 10px;
    position: absolute;
    top: 57px;
    left: 77px;
    z-index: 3;
    color: var(--red);
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledBox = styled.div`
    @media (min-width: 599.98px) {
        margin: -10px 0 -10px 18px;
        & > *{
            margin: 10px 0;
        }
    }

    @media (max-width: 599.98px) {
        margin: -10px 0 -10px 0px;
        & > *{
            margin: 10px 0;
        }
    }
    display:flex;
    flex-direction: column;
    
    justify-content: center;
    align-items: center;
`


function PageNotFound({language}){
    const languages = [
        {id: 'pl', link: '/pl', notFound: "NIE ZNALEZIONO STRONY", back: "Powrót do strony głównej"},
        {id: 'de', link: '/de', notFound: "SEITE NICHT GEFUNDEN", back: "Zurück zur Hauptseite"},
        {id: 'en', link: '/en', notFound: "PAGE NOT FOUND", back: "Back to main page"},
    ]
    return(
        <div>
            <Page bgcolor="whitesmoke">
                <PageContent>
                    <StyledPage>
                        <ContactBoxItem>
                            <StyledIcon>
                                <FontAwesomeIcon icon={faMagnifyingGlass}/>
                            </StyledIcon>
                        </ContactBoxItem>
                        {languages.map((lang) => (
                            (lang.id === language && 
                                <StyledBox key={lang.id}>
                                    <StyledText>
                                        <h1>
                                            {lang.notFound}
                                        </h1>
                                    </StyledText>
                                    <div style={{transform: "scale(85%)"}}>
                                        <Link to={lang.link}>
                                            <BlueRedButton>
                                                {lang.back}
                                            </BlueRedButton>
                                        </Link>
                                    </div>
                                </StyledBox>
                            )   
                        ))}
                    </StyledPage>
                </PageContent>
            </Page>
        </div>
    );
}
export default PageNotFound;