import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import { Paragraph } from "../../../components/templates/paragraph/paragraph"
import { PageText } from "../../../components/templates/pageText/pageText";
import "aos/dist/aos.css"
import MachinesCarousel from "../../../components/organisms/machinesCarousel/machinesCarousel";
import styled from "styled-components";

const Container = styled.div`
    @media (max-width: 767.98px) {
        flex-direction: column;
        margin: -25px 0;
        & > *{
            margin: 25px 0;
        }
    }
    
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        max-width: 720px;
        flex-direction: column;
        margin: -25px 0;
        & > *{
            margin: 25px 0;
        }
    }
        
    @media (min-width: 991.99px) and (max-width: 1199.98px) {
        margin: 0 -25px;
        & > *{
            margin: 0 25px;
        }
    }

    @media (min-width: 1199.99px) {
        margin: 0 -25px;
        & > *{
            margin: 0 25px;
        }
    }
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Box = styled.div`
    // flex: 1;
`

function MachinesEN(){
    return(
        <Page>
            <PageContent>
                <Container>
                    <Box data-aos-once data-aos-duration={1000} data-aos="fade-right">
                        <PageText>
                            <Paragraph>
                                Potencjał techniczny i ekonomiczny naszej firmy pozwala realizować zlecenia szybko i solidnie przy
                                jednoczesnym zachowaniu konkurencyjnych cen dostosowanych do potrzeb i oczekiwań Klientów.
                            </Paragraph>
                        </PageText>
                        <Paragraph>
                            Posiadamy najlepszy sprzęt specjalistyczny w postaci wiertnic firm Ditch Witch i Vermeer i Perforator.
                        </Paragraph>
                    </Box>
                    <Box data-aos-once data-aos-duration={1000} data-aos="fade-left">
                        <MachinesCarousel/>
                    </Box>
                </Container>
            </PageContent>
        </Page>
    );
}

export default MachinesEN;