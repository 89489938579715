import "../../../styles/organisms/form/form.css"
import React from "react";
import styled from "styled-components";

const StyledBox = styled.label`
    display: flex;
    position: relative;
    padding-top: 15px;
    padding-bottom: 25px;
    margin-bottom: 12px;
    padding-left: 27px;
    cursor: pointer;
    font-size: 22px;

    input {
        margin: 0;
        left: 0;
        top: 15.5px;
        position: absolute;
        width: 18px;
        height: 18px;
        background-color: var(--white);
        border-radius: 0%;
        vertical-align: middle;
        border: 2px solid var(--lightgrey);
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        transition: 0.3s ease;
    }

    input:checked {
        background-color: var(--blue);
        border: 2px solid var(--blue);
    }

    input:checked:after{
        content: '';
        position: absolute;
        left: 4px;
        top: 0px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    p{
        margin: 0;
        font-size: 14px;
    }

    a{
        color: var(--lightgrey);
        transition: 0.3s ease;
    }
    a:hover{
        color: var(--blue);
    }
`


function CheckBox({checkBoxLabel}){
    return(
        <StyledBox>
            <input type="checkbox" required={true}/>
            <p>{checkBoxLabel}</p>
        </StyledBox>
    )
}

export default CheckBox;