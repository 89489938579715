import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledIcon = styled.div`
@media (max-width: 429.98px) {
    font-size: 20px;
    min-width: 30px;
    min-height: 30px;
}
@media (min-width: 429.98px) {
    font-size: 25px;
    min-width: 50px;
    min-height: 50px;
}
-webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
padding: 5px;
color: var(--blue);
border: 2px solid var(--blue);
display: flex;
align-items: center;
justify-content: center;
order: ${(props) => props.right ? 2 : 1};
`

const StyledBox = styled.div`
display: grid;
grid-template-columns: auto auto;
align-items: center;
padding: 20px 0px;
margin-left: ${(props) => props.right ? "auto" : 0};

@media (max-width: 429.98px) {
    gap: 30px;
    grid-gap: 30px;
    width: 260px;
    justify-content: ${(props) => props.right ? "right" : "left"};
}

@media (min-width: 429.98px) {
    gap: 20px;
    grid-gap: 20px;
    justify-content: ${(props) => props.right ? "right" : "left"};
}
`

const StyledText = styled.div`
order: ${(props) => props.right ? 1 : 2};
@media (mix-width: 429.98px) {
    margin-left: 18px;
}

p{
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    text-align: ${(props) => props.right ? "right" : "left"};
}
`

function JobOfferItem({children, icon}){
    return(
        <StyledBox>
            <StyledIcon>
                <FontAwesomeIcon icon={icon}/>
            </StyledIcon>
            <StyledText>
                <p>
                    {children}
                </p>
            </StyledText>
        </StyledBox>
    );
}

export default JobOfferItem;