import { useState } from "react";
import "../../../styles/atoms/logoNavbar/logoNavbar.css"
function LogoNavbar({handleNavbarLoad,isMobile}){
    const [scroll, setScroll] = useState(false);
    window.addEventListener('scroll',()=> setScroll(window.scrollY > 50));
    
    return(
        <div className={isMobile ? "logoNavbar-mobile" : "logoNavbar"}>
            <img onLoad={handleNavbarLoad} className={!isMobile && scroll ? "shadow":""} src="/images/navbar/bormat-logo.png" alt="" />
        </div>
    );
}

export default LogoNavbar;
