import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import "aos/dist/aos.css"
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag"

const FlagContainer = styled.div`
    display: grid;
    gap: 20px;
    grid-gap: 20px;
    grid-template-columns: auto auto auto;
    align-items: center;
    padding: 20px 15px;
`
const StyledFlag = styled.div`
`

const StyledText = styled.div`
    p{
        font-size: 17px;
        font-weight: 600;
    }
`

function Flag({text, children}){
    return(
        <FlagContainer data-aos-once data-aos-duration={1000} data-aos={"zoom-in-right"}>
            <StyledFlag>
                {children}
            </StyledFlag>
            <StyledText>
                <p>{text}</p>
            </StyledText>
        </FlagContainer>
    );
}

export default Flag;