import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import { Page } from "../../components/templates/page/page"
import { PageText } from "../../components/templates/pageText/pageText";
import JobOfferForm from "../../components/organisms/jobOfferForm/jobOfferForm";
import { Header } from "../../components/templates/header/header";
import JobOfferItem from "../../components/organisms/jobOfferItem/jobOfferItem";
import JobOfferBox from "../../components/organisms/jobOfferBos/jobOfferBox";
import { faDumbbell } from '@fortawesome/free-solid-svg-icons'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faSnowplow } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import "../../styles/pages/jobOffer/jobOffer.css"
import CustomLink from "../../components/atoms/customLink/customLink";

function JobOfferDE() {
  const mailIDs = {
    service: process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
    template: process.env.REACT_APP_EMAIL_JS_JOB_OFFER_TEMPLATE_PL_ID,
    publicKey: process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY_ID,
  }

  const inputs = [
    {
      name: "name",
      placeholder: "Vorname",
      errorMessage: "Pflichtfeld",
      required: true,
      type: "text",
    },
    {
      name: "lastname",
      placeholder: "Name",
      errorMessage: "Pflichtfeld",
      required: true,
      type: "text",
    },
    {
      name: "phone-number",
      placeholder: "Telefonnummer",
      errorMessage: "Eine korrekte Telefonnummer eingeben (Format: 123456789)",
      required: true,
      type: "number",
      pattern: "[0,9]",
      min: "100000000",
    },
    {
      name: "email",
      placeholder: "Email-Adresse",
      errorMessage: "Eine korrekte Email-Adresse eingeben",
      required: true,
      type: "email",
    },
    {
      name: "message",
      placeholder: "Qualifikationen",
      errorMessage: "Pflichtfeld",
      required: true,
      type: "text",
    },
    // {
    //   name: "file",
    //   placeholder: "Plik",
    //   errorMessage: "Pole wymagane",
    //   required: true,
    //   type: "file",
    // },
  ];

    return (
      <div>
        <MainHeader bgcolor={"whitesmoke"}>STELLENANGEBOTE</MainHeader>
        <Page bgcolor={"whitesmoke"}>
          <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
            <div className="jobOffer-container">
              <div className="jobOffer-boxes">
                <JobOfferBox header={"Die von uns gesuchten Mitarbeiter sind:"}>
                  <JobOfferItem icon={faDumbbell}>
                    offen für neue Herausforderungen
                  </JobOfferItem>
                  <JobOfferItem icon={faGraduationCap}>
                    lernwillig
                  </JobOfferItem>
                </JobOfferBox>
                <JobOfferBox header={"Von Vorteil wäre(n):"}>
                  <JobOfferItem icon={faSnowplow}>
                    Baggerführerschein
                  </JobOfferItem>
                  <JobOfferItem icon={faTruck}>
                    Führerschein der Kat. C+E
                  </JobOfferItem>
                </JobOfferBox>
                </div>
                <PageText>
                  <Header>
                    Rufen Sie uns an oder füllen Sie das Formular aus. Wir rufen Sie zurück und vereinbaren gerne 
                    einen Gesprächstermin.
                  </Header>
                </PageText>
                <JobOfferForm 
                  checkBoxLabel={
                    <>
                      Hiermit bestätige ich, dass ich die 
                      <CustomLink to={"/de/private-policy"}> Datenschutzbestimmungen </CustomLink>
                      von www.bormat.de zur Kenntnis genommen habe
                    </>
                    }
                  mailIDs={mailIDs} 
                  button={"Senden"} 
                  header={"Kontaktformular"} 
                  inputs={inputs}
                >
                  <p>Ihre Nachricht wurde gesendet!</p>
                  <p>Wir haben eine Bestätigung Ihrer Nachricht an die angegebene E-Mail-Adresse geschickt.</p>
                </JobOfferForm>
            </div>
          </PageContent>
        </Page>
      </div>
    );
}
  
export default JobOfferDE;
  