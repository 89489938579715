import HomeMainDE from './main/mainDE';
import FlagsDE from './flags/flagsDE';
import OurOfferDE from './ourOffer/ourOfferDE';
import AboutUsDE from './aboutUs/aboutUsDE';
import AboutUsSnakeDE from './aboutUsSnake/aboutUsSnakeDE';
import DevicesDE from './devices/devicesDE';
import MachinesDE from './machines/machinesDE';
import BrandsDE from './brands/brandsDE';
import JobOfferHomeDE from './jobOfferHome/jobOfferHomeDE';
import ContactDE from './contact/contactDE';
import { useState } from 'react';
import LoadingScreen from '../../components/organisms/loadingScreen/loadingScreen';

function HomeDE({isNavbarLoaded}) {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const handlePageLoad = () =>{
    setTimeout(() =>{
      setIsPageLoaded(true);
    },1000)
  }

  return (
    <div className="home">
      <LoadingScreen isLoaded={isPageLoaded && isNavbarLoaded}/>
      <HomeMainDE handlePageLoad={handlePageLoad}/>
      <FlagsDE/>
      <OurOfferDE/>
      <AboutUsDE/>
      <AboutUsSnakeDE/>
      <DevicesDE/>
      <MachinesDE/>
      <BrandsDE/>
      <JobOfferHomeDE/>
      <ContactDE/>
    </div>
  );
}

export default HomeDE;
