import useWindowDimensions from "../../../functions/windowsDiemsions";
import { HeaderContainer, StyledMainHeader, HeaderWrapper} from "./mainHeader.styled";

export default function MainHeader({bgcolor, image, children}){
    const { height, width } = useWindowDimensions();
    const isMobile = width <= 991.98;
    return(
        <HeaderContainer isMobile={isMobile} bgcolor={bgcolor} image={image}>
            <HeaderWrapper>
                <StyledMainHeader data-aos-once  data-aos="fade-right" data-aos-duration={1000} border={true}>
                    {children}
                </StyledMainHeader>
            </HeaderWrapper>
        </HeaderContainer>
    );
}