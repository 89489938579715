import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "../../../styles/organisms/ourOffer/ourOfferItem.css"
import AnimatedArrow from "../../atoms/animatedArrow/animatedArrow";
import { Icon } from '@iconify/react';

function OurOfferItem({icon, label, children, rotate, vFlip}){
    const [hover, setHover] = useState(false);
    const handleMouseEnter = () =>{
        setHover(true);
    }

    const handleMouseLeave = () =>{
        setHover(false);
    }

    return(
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="ourOfferItem">
            <div className="ourOfferItem-content">
                {/* <FontAwesomeIcon className="icon" icon={icon}/> */}
                <Icon className="icon" icon={icon} rotate={rotate} vFlip={vFlip}/>
                {children}
            </div>
            <div className="read-more">
                {label}
                <AnimatedArrow hover={hover}/>
            </div>
        </div>
    );
}

export default OurOfferItem;