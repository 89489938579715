import { useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../styles/atoms/backToTopButton/backToTopButton.css"

function BackToTopButton(){
    const [showButton, setShowButton] = useState(false);
    const topofpage = window.scrollY === 0;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const backToTopRef = useRef();

  useEffect(()=>{
    if(showButton){
      gsap.fromTo(backToTopRef.current,
        {
          y :  75,
        }, {
          y : 0,
        })
      }
    else{
      gsap.fromTo(backToTopRef.current,
        {
          y :  0,
        }, {
          y : 75,
        })
    }
  });
    return(
        <button onClick={scrollToTop} topofpage={topofpage.toString()} className="back-to-top-btn" ref={backToTopRef}>
          <FontAwesomeIcon icon={faArrowUp}/>
        </button>
    );
}

export default BackToTopButton;