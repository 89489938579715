import "../../../styles/organisms/brandsCarousel/brand.css"

function Brand({number,link}){
    return(
        <div className="brand">
            <a href={link}>
                <img src={"images/home/brandsCarousel/brand"+number+".png"} alt=""/>
            </a>
        </div>
    );
}

export default Brand;