import BrandsCarousel from "../../../components/organisms/brandsCarousel/brandsCarousel";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Page } from "../../../components/templates/page/page";
import "aos/dist/aos.css"
import PageHeader from "../../../components/templates/pageHeader/pageHeader";

function BrandsPL(){
    return(
        <Page>
            <PageContent>
                <PageHeader>
                    Sukces firmy to praca z najlepszymi
                </PageHeader>
                <BrandsCarousel/>
            </PageContent>
        </Page>
    );
}

export default BrandsPL;