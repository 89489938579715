import styled from 'styled-components'
export const Header = styled.div`
@media (max-width: 299.99px){
    font-size: 6vw;
}
    color: var(--black);
    font-weight: 600;
    font-size: 25px;
    letter-spacing: 0.2vw;
    border-bottom: ${(props) => props.border ? "1px" : "0"} solid var(--lightgrey);
`
