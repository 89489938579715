import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { PageText } from "../../../components/templates/pageText/pageText";
import LoadingScreen from "../../../components/organisms/loadingScreen/loadingScreen";
import { useState } from "react";
import ContentAndImage from "../../../components/templates/contentAndImage/contentAndImage";
import { faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

function PZPL(){
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const handleImageLoaded = () => {
        setIsImageLoaded(true);
    }
    
    return(
        <div>
            <LoadingScreen isLoaded={isImageLoaded}/>
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                Prace ziemne
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent>
                    <ContentAndImage blue right horizontal={true} handleImageLoaded={handleImageLoaded} src={"/images/offer/prace-ziemne.jpg"}>
                        <Paragraph data-aos-once  data-aos="fade-right" data-aos-duration={1000}>
                            W realizacjach wykorzystujemy również własne koparki i wszelki sprzęt towarzyszący. 
                        </Paragraph>
                        <PageText>
                            <Paragraph data-aos-once  data-aos="fade-right" data-aos-duration={1000}>
                                Nasza baza logistyczno-transportowa pozwala działać nam na terenie całego kraju i nie tylko.
                            </Paragraph>
                        </PageText>
                    </ContentAndImage>
                   <PageText padding="90px 0">
                        <ContentAndImage blue horizontal={true} handleImageLoaded={handleImageLoaded} icon={faLeaf}>
                                <Paragraph data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                                    Wszystkie usługi z zakresu robót ziemnych są prowadzone przez naszą firmę z wykorzystaniem
                                    profesjonalnego sprzętu i specjalistycznych narzędzi. Staramy się minimalnie ingerować w istniejący
                                    ekosystem w miejscu wykonywania robót, dlatego są one przyjazne pod względem ekologicznym.
                                    Zdajemy sobie sprawę jak ważna jest równowaga ekosystemu dlatego dokładamy wszelkich starań by
                                    zachować jego pierwotny stan.
                                </Paragraph>
                        </ContentAndImage>
                   </PageText>
                    <ContentAndImage blue horizontal={true} handleImageLoaded={handleImageLoaded} icon={faClipboardList}>
                        <Paragraph data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                            Roboty ziemne - przygotowawcze, kanalizacyjne, wodociągowe czy wykopy są wykonywane wedle
                            wskazań zleceniodawców, zgodnie z przedstawionym projektem i wymaganiami odnośnie
                            prowadzenia dalszych prac - instalacyjnych lub budowlanych.
                        </Paragraph>
                    </ContentAndImage>
                </PageContent>
            </Page>
        </div>
    );
}
export default PZPL;