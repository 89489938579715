import CookieConsent from "react-cookie-consent";
import styled from "styled-components";
import CustomLink from "../../atoms/customLink/customLink";
import { PageContent } from "../../templates/pageContent/pageContent";

const StyledBox = styled.div`
    @media(max-width: 299.98px){ 
        max-width: 260px;
    }
    display: flex;
    flex-wrap;
    a{
        color: var(--lightgrey) !important;
        text-decoration: underline !important;
    }
`

function CookiePopUp({handleAcceptCookie, handleDeclineCookie, language}){
    return(
        <CookieConsent
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
            style={{ backgroundColor: "var(--darkgrey)" }}
            buttonClasses="cookie-button-accept"
            declineButtonClasses="cookie-button-decline"
            buttonText={language === 'pl' ? "Zgadzam się" : language === 'de' ? "Akzeptieren" : "Accept"}
            declineButtonText={language === 'pl' ? "Nie zgadzam się" : language === 'de' ? "Ablehnen" : "Decline"}
        >
            {
            language === 'pl' ? 
                <StyledBox>
                    <p>
                        Ta strona używa plików cookie.{" "}
                        <CustomLink to="/pl/private-policy">
                             Zapoznaj się z polityką prywatności.
                        </CustomLink>
                    </p>
                </StyledBox>
            : language === 'de' ?
            <StyledBox>
                    <PageContent>
                    <p>
                        Diese Website verwendet Cookies.{" "}
                        <CustomLink to="/de/private-policy">
                            Lesen Sie die Datenschutzerklärung.
                        </CustomLink>
                    </p>
                    </PageContent>
                </StyledBox>
            : 
                <StyledBox>
                    <p>
                        This website uses cookies.{" "}
                        <CustomLink to="/en/private-policy">
                            Read the privacy policy.
                        </CustomLink>
                    </p>
                </StyledBox>
            }

      </CookieConsent>
    );
}

export default CookiePopUp;