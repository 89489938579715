import React from "react";
import { Page } from "../../components/templates/page/page";
import { PageContent } from "../../components/templates/pageContent/pageContent";
import MainHeader from "../../components/templates/mainHeader/mainHeader";
import { PageText } from "../../components/templates/pageText/pageText";
import styled from "styled-components";

const StyledOl = styled.ol`
    margin: 15px 0;
    p{
        margin-left: 15px;
    }
    li{
        padding-top: ${(props) => props.type === "I" ? "15px" : "5px"};
    }
    letter-spacing: 0.2vw;
`

const BoldLi = styled.li`
    font-weight: bold;
`
const Container = styled.div`
@media (max-width: 349.99px){
    p{
        font-size: 4vw !important;
    }
}
`

function PrivacyPolicyPL(){
    return(
        <Container>
            <MainHeader bgcolor="whitesmoke" image="">
                POLITYKA PRYWATNOŚCI SERWISU www.bormat.de
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <p>
                        Ochrona prywatności Użytkowników jest dla <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ 
                        ODPOWIEDZIALNOŚCIĄ</strong> szczególnie ważna. Z tego względu Użytkownicy Serwisu 
                        <strong> www.bormat.de</strong> mają zagwarantowane wysokie standardy ochrony prywatności. 
                        <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ</strong> jako administrator danych 
                        dba o bezpieczeństwo udostępnionych przez Użytkowników danych.
                    </p>
                    <p>
                        Celem Administratora jest również należyte informowanie Użytkowników o prawach i obowiązkach 
                        związanych z przetwarzaniem danych osobowych, szczególnie wobec treści przepisów o ochronie 
                        danych osobowych określonych w rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z 
                        dnia 27.04.2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych 
                        i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE 
                        (dalej jako „RODO”). Dlatego też, dbając o ochronę prywatności Użytkowników Serwisu, 
                        w niniejszym dokumencie Administrator informuje o podstawach prawnych przetwarzania
                        danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich z Serwisu 
                        <strong> www.bormat.de</strong> (dalej jako „Serwis”), sposobach zbierania, przetwarzania i 
                        ochrony danych osobowych, a także o prawach Użytkowników.
                    </p>
                    <p>
                        Użytkownikiem jest każda osoba fizyczna, której dane dotyczą, korzystająca z serwisu internetowego 
                        <strong> www.bormat.de</strong> lub usług elektronicznych dostępnych za pośrednictwem Serwisu.
                    </p>
                    <p>
                        Administratorem danych osobowych udostępnionych przez Użytkownika w Serwisie <strong> www.bormat.de </strong>
                        jest <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, Jaworowa 19 09-400 Maszewo Duże, 
                        NIP 7742975568,</strong> (dalej jako „Administrator”).
                    </p>
                    <StyledOl type="I">
                        <BoldLi>
                            <p>ZGODA UŻYTKOWNIKA</p>
                        </BoldLi>
                        <p>
                            Korzystanie z Serwisu<strong> www.bormat.de </strong>przez Użytkownika oznacza, że Użytkownik 
                            akceptuje, że Administrator gromadzi oraz wykorzystuje dane osobowe zgodnie z niniejszą Polityką
                            Prywatności.
                        </p>
                        <p>
                            Dane osobowe Użytkownika Serwisu są przetwarzane przez Administratora w oparciu o jego 
                            zgodę, zaś w niektórych przypadkach opisanych w niniejszym dokumencie, w ramach prawnie 
                            uzasadnionego interesu Administratora. Użytkownik ma prawo w dowolnym momencie wycofać 
                            wyrażoną wcześniej zgodę. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, 
                            którego dokonano na podstawie zgody przed jej wycofaniem.
                        </p>
                        <p>
                            W przypadku, gdy nastąpi zmiana niniejszej Polityki Prywatności, a Użytkownik nadal będzie 
                            korzystał z Serwisu, to poczytuje się to za wyrażenie zgody na aktualne warunki Polityki 
                            Prywatności.
                        </p>
                        <BoldLi>
                            <p>DANE OSOBOWE PRZETWARZANE PRZEZ ADMINISTRATORA</p>
                        </BoldLi>
                        <StyledOl>
                            <BoldLi>
                                <p>Sposób pozyskiwania danych osobowych</p>
                            </BoldLi>
                            <p>
                                Administrator pozyskuje dane osobowe bezpośrednio od Użytkownika za pośrednictwem 
                                Serwisu, poprzez wypełnienie przez Użytkownika formularza kontaktowego dostępnego 
                                w Serwisie i przesłanie za jego pośrednictwem wiadomości do Administratora.
                            </p>
                            <p>
                                Podanie danych osobowych zawartych w formularzu kontaktowym jest dobrowolne
                            </p>
                            <BoldLi>
                                <p>Rodzaje przetwarzanych danych osobowych</p>
                            </BoldLi>
                            <p>
                                Administrator gromadzi za pośrednictwem Serwisu następujące dane osobowe dotyczące 
                                Użytkownika:
                            </p>
                            <StyledOl>
                                <li>Imię i nazwisko;</li>
                                <li>Adres e-mail;</li>
                                <li>Numer telefonu;</li>
                                <li>Treść wiadomości;</li>
                                <li>Temat wiadomości;</li>
                                <li>Nazwa Firmy.</li>
                            </StyledOl>
                        </StyledOl>
                        <BoldLi>
                            <p>CELE PRZETWARZANIA DANYCH OSOBOWYCH</p>
                        </BoldLi>
                        <p>
                            Sposób przetwarzania przez Administratora danych dotyczących Użytkownika zależy od sposobu 
                            korzystania przez Użytkownika z Serwisu i dostępnych w nim funkcjonalności. Administrator 
                            przetwarza dane osobowe Użytkownika w następujących celach:
                        </p>
                        <StyledOl>
                            <BoldLi>
                                <p>Komunikacji z Użytkownikiem.</p>
                            </BoldLi>
                            <p>
                                Administrator wykorzystuje dane osobowe Użytkownika w celu komunikacji z nim w 
                                spersonalizowany sposób. Komunikowane Użytkownikowi informacje dotyczą oferowanych 
                                produktów lub usług, bezpieczeństwa danych osobowych, aktualizacji sieci, przypomnień, 
                                ale również sugerowanych ofert Administratora lub jego partnerów. Komunikacja z 
                                Użytkownikiem dotyczy także obsługi Użytkownika. Dane osobowe wykorzystywane są w celu 
                                pomocy Użytkownikowi, rozwiązaniu problemów technicznych oraz odpowiedzi na jego skargi 
                                lub reklamacje.
                            </p>
                            <BoldLi>
                                <p>Przedstawiania Użytkownikowi ofert handlowych drogą elektroniczną.</p>
                            </BoldLi>
                            <p>
                                Celem korzystania z danych osobowych Użytkownika udostępnionych przez niego za 
                                pośrednictwem formularza kontaktowego dostępnego w Serwisie jest komunikacja marketingowa 
                                prowadzona przez Administratora w ramach prowadzonej działalności, w szczególności 
                                przedstawianie ofert handlowych Użytkownikowi drogą elektroniczną.
                            </p>
                            <BoldLi>
                                <p>Przedstawiania Użytkownikowi ofert handlowych w kontakcie telefonicznym.</p>
                            </BoldLi>
                            <p>
                                Celem korzystania z danych osobowych Użytkownika udostępnionych przez niego za 
                                pośrednictwem formularza kontaktowego dostępnego w Serwisie jest komunikacja 
                                marketingowa prowadzona przez Administratora w ramach prowadzonej działalności, 
                                w szczególności poprzez przedstawianie ofert handlowych Użytkownikowi w kontakcie 
                                telefonicznym.
                            </p>
                            <BoldLi>
                                <p>
                                    Umożliwienia przesyłania przez Użytkownika komentarzy lub opinii.
                                </p>
                            </BoldLi>
                            <p>
                                Administrator wykorzystuje dane osobowe Użytkownika w celu umożliwienia mu 
                                komentowania/opiniowania działalności, usług lub produktów Administratora lub podmiotów 
                                z nim współpracujących.
                            </p>
                        </StyledOl>
                        <p>
                            Administrator może przetwarzać dane osobowe Użytkownika podane w formularzu kontaktowym 
                            również w następujących celach:
                        </p>
                        <StyledOl type="a">
                            <li>
                                <p>
                                	w celu zawarcia i realizacji ewentualnej umowy pomiędzy Użytkownikiem a Administratorem oraz obsługi Użytkownika jako klienta Administratora zgodnie z art. 6 ust. 1 lit. b) RODO;
                                </p>
                            </li>
                            <li>
                                <p>
                                	w celu prowadzenia rozliczeń finansowych z Użytkownikiem będącym klientem Administratora
                                     tytułem realizacji ewentualnej umowy zawartej między stronami, a także ewentualnego 
                                     dochodzenia roszczeń od Użytkownika będącego klientem w ramach prawnie uzasadnionego 
                                     interesu Administratora zgodnie z art. 6 ust. 1 lit. f) RODO oraz spełnienia obowiązków
                                      prawnych Administratora wobec organów podatkowych na podstawie odrębnych przepisów
                                       zgodnie z art. 6 ust. 1 lit. c) RODO;
                                </p>
                            </li>
                            <li>
                                <p>
                                	w celu realizacji działań marketingowych Administratora w ramach prawnie uzasadnionego 
                                    interesu Administratora w rozumieniu art. 6 ust. 1 lit. f) RODO, a także zgodnie z 
                                    oświadczeniami woli dotyczącymi komunikacji marketingowej złożonymi wobec 
                                    Administratora. Zgody udzielone w zakresie komunikacji marketingowej (np. na 
                                    przesyłanie informacji handlowych drogą elektroniczną lub kontakt telefoniczny 
                                    w celach marketingu bezpośredniego) mogą być wycofane w dowolnym czasie, bez wpływu 
                                    na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej 
                                    cofnięciem;
                                </p>
                            </li>
                            <li>
                                <p>
                                	w celu realizacji obowiązków prawnych Administratora wobec Użytkownika określonych w 
                                    RODO, w rozumieniu art. 6 ust. 1 lit. c) RODO.
                                </p>
                            </li>
                        </StyledOl>
                        <BoldLi>
                            <p>
                            	UDOSTĘPNIANIE DANYCH OSOBOWYCH
                            </p>
                        </BoldLi>
                        <p>
                            Dane osobowe Użytkownika nie są przekazywane przez Administratora podmiotom trzecim.
                        </p>
                        <BoldLi>
                            <p>
                            	PRAWA UŻYTKOWNIKA
                            </p>
                        </BoldLi>
                        <StyledOl>
                            <BoldLi>
                                <p>
                                	Prawa Użytkownika
                                </p>
                            </BoldLi>
                            <p>
                                Użytkownik na każdym etapie przetwarzania jego danych zapewniony ma szereg uprawnień 
                                pozwalających mu uzyskać dostęp do swoich danych, weryfikację prawidłowości przetwarzania 
                                danych, ich korektę, jak również ma prawo zgłoszenia sprzeciwu wobec ich przetwarzania,
                                 może żądać usunięcia danych, ograniczenia przetwarzania lub przeniesienia danych.
                            </p>
                            <p>
                                W przypadku chęci skorzystania przez Użytkownika z przysługujących mu praw jako podmiotu 
                                danych osobowych, może on się skontaktować z Administratorem za pomocą następujących 
                                danych kontaktowych: <strong>„BORMAT” SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, Jaworowa 19, 
                                09-400 Maszewo Duże, mboerner@bormat.com.pl.</strong>
                            </p>
                            <BoldLi>
                                <p>
                                	Prawo wniesienia skargi do organu nadzorczego
                                </p>
                            </BoldLi>
                            <p>
                                Użytkownik, którego dane osobowe są przetwarzane przez Administratora ma prawo wnieść 
                                skargę do organu nadzoru właściwego w sprawach ochrony danych osobowych (Prezesa Urzędu 
                                Ochrony Danych Osobowych).
                            </p>
                        </StyledOl>
                        <BoldLi>
                            <p>PLIKI COOKIES</p>
                        </BoldLi>
                        <StyledOl>
                            <li>
                                <p>
                                	Administrator informuje, iż podczas korzystania z Serwisu w urządzeniu końcowym 
                                    Użytkownika zapisywane są krótkie informacje tekstowe zwane „cookies”. Pliki „cookies” 
                                    zawierają takie dane informatyczne jak: adres IP dotyczący Użytkownika, nazwa strony 
                                    internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym 
                                    Użytkownika, zapis parametrów i statystyki oraz unikalny numer. Pliki „cookies” są 
                                    kierowane do serwera Serwisu za pośrednictwem przeglądarki internetowej zainstalowanej 
                                    w urządzeniu końcowym Użytkownika.
                                </p>
                            </li>
                            <p>
                                Pliki „cookies” wykorzystywane są w Serwisie w celu:
                            </p>
                            <StyledOl type="a">
                                <li>
                                    <p>
                                    	utrzymania technicznej poprawności i ciągłości sesji pomiędzy serwerem Serwisu, 
                                        a urządzeniem końcowym Użytkownika;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	optymalizacji korzystania przez Użytkownika ze stron internetowych Serwisu i 
                                        dostosowania sposobu ich wyświetlania na urządzeniu końcowym Użytkownika;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	zapewnienia bezpieczeństwa korzystania z Serwisu;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	zbierania statystyk odwiedzin stron Serwisu wspierających ulepszanie ich 
                                        struktury i zawartości;
                                    </p>
                                </li>
                            </StyledOl>
                            <p>
                                W ramach Serwisu stosowane są dwa rodzaje plików „cookies”: „sesyjne” oraz „stałe”. 
                                „Sesyjne” pliki „cookies” są plikami ulegającymi automatycznemu usunięciu z urządzenia 
                                końcowego Użytkownika Serwisu po jego wylogowaniu z Serwisu lub po opuszczeniu przez niego 
                                stron internetowych Serwisu lub po wyłączeniu przeglądarki internetowej. „Stałe” pliki 
                                „cookies” przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w 
                                parametrach plików „cookies” lub do czasu ich usunięcia przez Użytkownika. „Stałe” 
                                pliki „cookies” instalowane są w urządzeniu końcowym Użytkownika wyłącznie za jego zgodą.
                            </p>
                            <p>Administrator informuje, że:</p>
                            <StyledOl type="a">
                                <li>
                                    <p>
                                    	przeglądarki internetowe domyślnie akceptują instalowanie plików „cookies” 
                                        w urządzeniu końcowym Użytkownika. Każdy Użytkownik Serwisu może dokonać 
                                        w dowolnym czasie zmiany ustawień dotyczących plików „cookies” w używanej 
                                        przez niego przeglądarce internetowej w taki sposób, aby przeglądarka 
                                        automatycznie blokowała obsługę plików „cookies”, bądź informowała Użytkownika 
                                        o ich każdorazowym zamieszczeniu w jego urządzeniu końcowym. Szczegółowe 
                                        informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w 
                                        ustawieniach przeglądarki internetowej stosowanej przez Użytkownika Serwisu.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    	ograniczenie stosowania plików „cookies” przez Użytkownika może ujemnie wpłynąć 
                                        na poprawność i ciągłość świadczenia Usług w Serwisie.
                                    </p>
                                </li>
                            </StyledOl>
                            <p>
                                Pliki „cookies” zainstalowane w urządzeniu końcowym Użytkownika Serwisu mogą być 
                                wykorzystywane przez współpracujących z Administratorem reklamodawców lub partnerów 
                                biznesowych. Pliki „cookies” można uznać za dane osobowe jedynie w powiązaniu z innymi 
                                danymi identyfikującymi tożsamość, udostępnionymi Administratorowi przez Użytkownika w 
                                ramach korzystania z Serwisu. Dostęp do plików „cookies” przetwarzanych przez serwer Serwisu 
                                posiada wyłącznie Administrator. Jeśli Użytkownik nie zgadza się na zapisywanie i odbieranie 
                                informacji w plikach „cookies”, może zmienić zasady dotyczące plików „cookies” za pomocą 
                                ustawień swojej przeglądarki internetowej.
                            </p>
                        </StyledOl>
                        <BoldLi>
                            <p>INNE WAŻNE INFORMACJE</p>
                        </BoldLi>
                        <StyledOl>
                            <BoldLi>
                                <p>Ochrona bezpieczeństwa danych osobowych</p>
                            </BoldLi>
                            <p>
                                Administrator wprowadza odpowiednie środki mające na celu zapewnienie bezpieczeństwa 
                                danych osobowych Użytkownika. Bezpieczne korzystanie z Serwisu zapewniają stosowane 
                                systemy oraz procedury chroniące przed dostępem oraz ujawnieniem danych osobom 
                                niepożądanym. Ponadto stosowane przez Administratora systemy oraz procesy są regularnie 
                                monitorowane w celu wykrycia ewentualnych zagrożeń. Pozyskane przez Administratora dane 
                                osobowe przechowywane są w systemach komputerowych, do których dostęp jest ściśle 
                                ograniczony.
                            </p>
                            <BoldLi>
                                <p>Przechowywanie danych osobowych</p>
                            </BoldLi>
                            <p>
                                Okres przechowywania danych osobowych Użytkowników uzależniony jest od celów przetwarzania 
                                przez Administratora danych. Administrator przechowuje dane osobowe przez taki okres, 
                                jaki jest konieczny do osiągnięcia określonych celów, tj.:
                            </p>
                            <StyledOl type="a">
                                <li>
                                    <p>
                                    	przez okres prowadzenia działalności gospodarczej przez Administratora.
                                    </p>
                                </li>
                            </StyledOl>
                            <p>
                                W każdym z powyższych przypadków, po upływie niezbędnego okresu przetwarzania, 
                                dane mogą być przetwarzane tylko w celu dochodzenia roszczeń na tle łączących strony 
                                relacji do czasu ostatecznego rozstrzygnięcia tych roszczeń na drodze prawnej.
                            </p>
                            <BoldLi>
                            	<p>Zmiany Polityki prywatności</p>
                            </BoldLi>
                            <p>
                                W celu uaktualnienia informacji zawartych w niniejszej Polityce prywatności oraz jej 
                                zgodności z obowiązującymi przepisami prawa, niniejsza Polityka prywatności może ulec 
                                zmianie. W przypadku zmiany treści Polityki prywatności, zmieniona zostanie data jej 
                                aktualizacji wskazana na końcu jej tekstu. W celu zasięgnięcia informacji o sposobie 
                                ochrony danych osobowych, Administrator rekomenduje Użytkownikom regularne zapoznawanie 
                                się z postanowieniami Polityki Prywatności.
                            </p>
                            <BoldLi>
                            	<p>Informacje kontaktowe</p>
                            </BoldLi>
                            <p>
                                W celu uzyskania jakichkolwiek informacji dotyczących niniejszej Polityki Prywatności, 
                                Użytkownik może skontaktować się z Administratorem danych osobowych: <strong>„BORMAT” 
                                SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ, Jaworowa 19 09-400 Maszewo Duże, </strong>
                                z wykorzystaniem następujących danych kontaktowych: <strong>mboerner@bormat.com.pl.</strong>
                            </p>
                            <p>
                                Ponadto istnieje również możliwość kontaktu drogą pocztową pod adresem: <strong>Jaworowa 
                                19 09-400 Maszewo Duże.</strong>
                            </p>
                        </StyledOl>
                    </StyledOl>
                    <p>
                        Ostatnia aktualizacja niniejszego dokumentu miała miejsce dnia 07.10.2022r.
                    </p>
                </PageContent>
            </Page>
        </Container>
    )
}

export default PrivacyPolicyPL;