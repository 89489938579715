import "../../../styles/organisms/footer/footer.css"
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import FooterItem from "./footerItem";
import { PageContent } from "../../templates/pageContent/pageContent";
import React from "react"
import CustomLink from "../../atoms/customLink/customLink"
import { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"

function Footer({language, setFooterHeight}){
    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    const pl ={
        to: "/pl/private-policy",
        label: "Polityka Prywatności"
    }

    const de ={
        to: "/de/private-policy",
        label: "Datenschutz"
    }

    const en ={
        to: "/en/private-policy",
        label: "Polityka Prywatności"
    }

    useEffect(() => {
        setHeight(ref.current.clientHeight);
        setFooterHeight(height);
    })
    
    return(
        <div ref={ref} className="footer">
            <PageContent>
                <div className="footerGrid">
                    <FooterItem icon={faLocationDot}>
                        <ul>
                            <li>Bormat Sp. z.o.o.</li>
                            <li>ul. Jaworowa 19</li>
                            <li>09-400 Maszewo Duże</li>
                            <br />
                            <li>Bormat GmbH</li>
                            <li>Störtebekerweg 8a</li>
                            <li>23669 Timmendorfer Strand</li>
                        </ul>
                    </FooterItem>
                    <FooterItem icon={faPhone}>
                        <ul>
                            <li>
                                <div>
                                    tel/fax:
                                </div>
                                <div>
                                    +48 24 366 65 02
                                </div>
                            </li>
                            <br />
                            <li>
                                <div>
                                    mobile:
                                </div>
                                <div>
                                    +49 151 25988034 
                                    +49 151 27646862
                                </div>
                            </li>
                        </ul>
                    </FooterItem>
                    <FooterItem icon={faEnvelope}>
                        <ul>
                            <li>mboerner@bormat.com.pl</li>
                        </ul>
                    </FooterItem>
                    <FooterItem icon={faFile}>
                        <ul>
                            <li>
                                {language === 'pl' ? 
                                    <CustomLink to={pl.to}>{pl.label}</CustomLink>
                                    :
                                    language === 'de' ? 
                                    <CustomLink to={de.to}>{de.label}</CustomLink>
                                    :
                                    <CustomLink to={en.to}>{en.label}</CustomLink>
                                }
                                
                            </li>
                        </ul>
                    </FooterItem>
                </div>
            </PageContent>
        </div>
    )
}

export default Footer;