import MainHeader from "../../../components/templates/mainHeader/mainHeader";
import { Paragraph } from "../../../components/templates/paragraph/paragraph";
import { Page } from "../../../components/templates/page/page";
import { PageContent } from "../../../components/templates/pageContent/pageContent";
import { PageText } from "../../../components/templates/pageText/pageText";
import { Header } from "../../../components/templates/header/header";
import { PageTilesStyled } from "../../../components/templates/pageTiles/pageTiles.styled";
import PageTile from "../../../components/templates/pageTiles/pageTile";
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import { faRoadBarrier } from '@fortawesome/free-solid-svg-icons'
import { faWater } from '@fortawesome/free-solid-svg-icons'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import AccordionDE from "../../../components/templates/accordion/accordionDE";
import useWindowDimensions from "../../../functions/windowsDiemsions";

function HPSDE(){
    const { width, height } = useWindowDimensions();
    const isDesktop = width > 991.98;

    return(
        <div>
            <MainHeader bgcolor="whitesmoke" image="/images/home/main-background.jpg">
                GESTEUERTE HORIZONTAL-SPÜLBOHRUNGEN
            </MainHeader>
            <Page bgcolor="whitesmoke">
                <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
                    <Paragraph bold={true}>
                        Gesteuerte Spülbohrungen gehören zu den grabenlosen Verfahren bei der Verlegung von 
                        Rohrleitungen unter Geländehindernissen - wie z.B.:
                        <ul>
                            <li>Straßen,</li>
                            <li>Bürgersteigen,</li>
                            <li>geschützten Grünflächen,</li>
                            <li>Gleisen,</li>
                            <li>Flüssen,</li>
                            <li>Entwässerungsgräben,</li>
                            <li>anderen Gewässern.</li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Die Technologie der gesteuerten Spülbohrungen beruht auf dem Prinzip, ein Bohrloch zu 
                        bohren und seinen Durchmesser entsprechend aufzuweiten, während gleichzeitig das ausgehobene 
                        Bohrgut mit Hilfe einer speziell ausgewählten Bohrspülung abgeführt wird, um ein geeignetes 
                        Schutzrohr oder ein Kabel einzuziehen.
                    </Paragraph>
                    <PageText>
                        <Header>Das gesamte Verfahren besteht aus drei Phasen:</Header>
                        <AccordionDE/>
                    </PageText>
                    <PageText>
                        <Header>Erzeugung von Bohrspülung</Header>
                        <br />
                        <Paragraph>
                            Vorbereitung der Bohrspülung - besteht in der Auswahl eines geeigneten Bentonits 
                            (auf der Basis von organischen Polymeren, behandelt mit zusätzlichen chemischen 
                            Substanzen mit leichtem Sandgehalt - ein Mittel für die richtige Verdichtung des Wassers, 
                            so dass es eine vorübergehende dauerhafte Suspensionsstruktur mit den erforderlichen 
                            Parametern bildet - Übersättigung mit dem natürlichen Boden, Fließen und Stabilisierung 
                            des Bohrlochs) und dessen Vermischung mit Wasser im richtigen Verhältnis, um die richtige 
                            Menge und Qualität der Bohrspülung zu erhalten, die, wenn sie direkt in das Bohrloch 
                            geleitet wird, die Durchführung der Arbeiten ermöglicht.
                        </Paragraph>
                    </PageText>
                    <PageText>
                        <Header>Durch den Einsatz der gesteuerten Spülbohrtechnik kann Folgendes vermieden werden:</Header>
                        <PageTilesStyled>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faPersonDigging}>
                                <Paragraph>offener Aushub, da die Arbeiten direkt an der Erdoberfläche beginnen</Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faRoadBarrier}>
                                <Paragraph>Begrenzung des Fahrzeugverkehrs beim Überqueren von Verkehrswegen</Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faWater}>
                                <Paragraph>Beeinträchtigung von Flussufern und Deichen</Paragraph>
                            </PageTile>
                            <PageTile isDesktop={isDesktop} width={"200px"} height={"200px"} icon={faLeaf}>
                                <Paragraph>erhebliche Eingriffe in die natürliche Umwelt und die Architektur</Paragraph>
                            </PageTile>
                        </PageTilesStyled>
                    </PageText>
                </PageContent>
            </Page>
        </div>
    );
}
export default HPSDE;