import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { Pagination, Navigation } from "swiper";
import "../../../styles/organisms/machinesCarousel/machinesCarousel.css"

function MachinesCarousel(){
    const machines = [
        {
            id: 1,
            text: "Ditch Witch JT30"
        },
        {
            id: 2,
            text: "Vermeer D24x40"
        },
        {
            id: 3,
            text: "Ditch Witch AT32"
        },
        {
            id: 4,
            text: "DigiTrack Falcon F5"
        },
    ]
    const [currentNumber, setCurrentNumber] = useState(0);
    const pagination = {
        clickable: true,
      };
    return(
        <div className="machines-container">
            <div className="machines-carousel">
            <Swiper
                spaceBetween={5}
                grabCursor={true}
                pagination={pagination}
                navigation={true}
                modules={[Pagination, Navigation]}
                onSlideChange={(swiper) => {setCurrentNumber(swiper.snapIndex)}}
            >
                {machines.map((machine) =>(
                    <SwiperSlide key={machine.id}>
                        <img src={"/images/home/machinesCarousel/machine-"+machine.id+".jpg"} alt=""/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
            <div className="machines-text">
                {machines[currentNumber].text}
            </div>
        </div>
    );
}

export default MachinesCarousel;