import { useState } from "react";
import styled from "styled-components";
import { PageContent } from "../../templates/pageContent/pageContent";
import { Page } from "../../templates/page/page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { useEffect } from "react";
import LoadingScreen from "../loadingScreen/loadingScreen";

const Gallery = styled.div`

    @media (max-width: 991.98px){
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 50%;
        -moz-column-width: 50%;
        column-width: 50%;
    }

    @media (min-width: 991.98px){
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;    
    }
    
    img{
        width: 100%;
        padding: 5px 0px;
        cursor: pointer;
    }

    img:hover{
        filter: opacity(.8);
    }
`

const Background = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--black);
    visibility: ${(props) => props.visible ? "visible" : "hidden"};
    opacity: ${(props) => props.visible ? "50%" : "0"};
    transform: scale(${(props) => props.visible ? "1" : "0"});
    overflow: hidden;
    z-index: 9999;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease-in-out;
`

const CloseButton = styled.div`
    visibility: ${(props) => props.visible ? "visible" : "hidden"};
    z-index: 10001;
    position: absolute;
    color: var(--red);
    font-size: 15px;
    transition: ${(props) => props.visible ? "0.3s ease" : "0"};
    cursor: pointer;
    top: 10px;
    right: 10px;
    padding: 3px;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    opacity: 80%;

    &:hover{
        color: var(--blue);
        opacity: 100%;
    }

    .icon{
        margin: auto;
    }
`

const FullImage = styled.div`
    visibility: ${(props) => props.visible ? "visible" : "hidden"};
    opacity: ${(props) => props.visible ? "100%" : "0"};
    transform: scale(${(props) => props.visible ? "1" : "0"});
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease-in-out;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 539.98px) {
        .swiper{
            padding-left: 5%;
            padding-right: 5%;
        }
    }

    @media (max-width: 767.98px) {
        .swiper{
            max-width: 540px;
        }
        .swiper-wrapper{
            max-width: 540px;
        }
    }
    
    @media (min-width: 767.99px) and (max-width: 991.98px) {
        .swiper{
            width: 720px;
        }
        .swiper-wrapper{
            max-width: 720px;
        }
    }
        
    @media (min-width: 991.99px) and (max-width: 1199.98px) {
        .swiper{
            width: 960px;
        }
        .swiper-wrapper{
            max-width: 960px;
        }
    }
        
    @media (min-width: 1199.99px) {
        .swiper{
            width: 1140px;
        }
        .swiper-wrapper{
            max-width: 1140px;
        }
    }

    .swiper-slide{
        width: 100%;
        height: fit-content !important;
        text-align: center;
        margin: auto;
    }

    img{
        max-width: 100%;
        max-height: 80vh;
        margin: auto;
    }
    .swiper-button-next, .swiper-button-prev{
        color: var(--lightgrey);
        transition: 0.3s ease;
    }
    .swiper-button-next:hover, .swiper-button-prev:hover{
        color: var(--white);
        transition: 0.3s ease;
    }
`

function GalleryComp() {
    const quantityOfPhotos = 35;

    const photos = [];
    for(var i = 1; i <= quantityOfPhotos; i++){
        photos.push(
            {
                src: '/images/gallery/bormat-gallery ('+i+').jpg',
                key: i,
            }
        );
    }

    const [visible, setVisible] = useState(false);
    const [clickedPhotoNumber, setClickedPhotoNumber] = useState(0);

    const onImageClicked = (photo) =>{
        setClickedPhotoNumber(photo.key);
        setVisible(true);
    }

    const handleClose = () =>{
        setVisible(false);
    }

    const [allImagesLoaded, setAllImagesLoaded] = useState(false);
    const [loadedImages, setLoadedImages] = useState(0);

    const handleImageLoaded = () => {
        setLoadedImages(currentValue => currentValue + 1);
    }
    
    useEffect(() => {
        setAllImagesLoaded(loadedImages === quantityOfPhotos);
    },[loadedImages]);

    return (
      <div>
        <FullImage visible={visible}>
            <CloseButton onClick={handleClose} visible={visible}>
                <FontAwesomeIcon className="icon" icon={faXmark}/>
            </CloseButton>
            {visible &&
                <Swiper  
                    mousewheel={true}
                    spaceBetween={100}
                    navigation={true}
                    modules={[Navigation]}
                    initialSlide={clickedPhotoNumber-1}
                >
                    {photos.map((photo)=>(
                        <SwiperSlide key={photo.key}>
                            <img  key={photo.key} src={photo.src} alt=""/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }
        </FullImage>
        <Background visible={visible}/>
        <Page bgcolor={"whitesmoke"}>
            <LoadingScreen isLoaded={allImagesLoaded}/>
          <PageContent data-aos-once  data-aos="fade-left" data-aos-duration={1000}>
            <Gallery>
                {photos.map((photo)=>(
                    <img onClick={() => onImageClicked(photo)} key={photo.key} onLoad={handleImageLoaded} src={photo.src} alt=""/>
                ))}
            </Gallery>
          </PageContent>
        </Page>
      </div>
    );
}
  
export default GalleryComp;